import React, { useState} from 'react';
import styled from "styled-components";
import Slider from "react-slick";
import {SLIDE_DATA} from "./slideData";
import SlideContentBox from "./SlideContentBox";

import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {theme} from "../../styles/theme";
import {comingSoonDeco, slideDim, slideFrame, slideFramM} from "../../assets/images";
import {useMediaQuery} from "react-responsive";

const GameSlide = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const handleBeforeChange = (oldIndex: number, newIndex: number) => {
        setCurrentIndex(newIndex);
    };
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    });
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const bgSlider = {
        infinite: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        dots: true,
        fade: true,
        speed: 1000,
        arrows: true,
        nextArrow: <NextArrow currentIndex={currentIndex} />,
        prevArrow: <PrevArrow currentIndex={currentIndex} />,
        asNavFor:nav2,
        beforeChange: handleBeforeChange,
        customPaging: (i: number) => {
            const isActive = i === currentIndex; // 현재 페이지가 활성 상태인지 확인

            const imgName = isActive ? `thumb_on_${i + 1}.png` : `thumb_off_${i + 1}.png`;

            return (
                <div
                    className={"customDots"}
                    style={{backgroundImage: `url(${getImage(imgName)})`}}
                >
                    {i===3 && <span className={"dim outlinedText"} data-text={"Coming Soon"}>Coming Soon</span>}
                </div>
            );
        },
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    touchMove: true,
                    swipeToSlide: true,
                },
            }
        ]
    }
    const contentSlider = {
        infinite: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        touchMove: false,
        fade: true,
        speed: 300,
        asNavFor:nav1,
        arrows: false,
        dots: true,
        cssEase: "ease-in-out",
        afterChange: function(e : number) {
            setCurrentIndex(e);
        },
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    dots: false,
                    touchMove:true,
                },
            }
        ]
    }
    const getImage = (imgName: string) => require(`../../assets/images/${imgName}`);
    return (
        <SlideWrap>
            <Slider
                {...bgSlider}
                className={"gameBgSlider"}
                ref={(slider1:any) => setNav1(slider1)}
            >
                {SLIDE_DATA.map((item) => <SlideBgBox key={item.id}><img src={getImage(`slide_game_bg_${item.id}${isMobile ?`_m` : ""}.jpg`)} alt=""/></SlideBgBox>)}
            </Slider>
            <ContentWrap>
                <Slider
                    {...contentSlider}
                    className={"gameContentSlider"}
                    ref={(slider2:any) => setNav2(slider2)}
                >
                    {SLIDE_DATA.map((item) => <SlideContentBox key={item.id} data={item} currentIndex={currentIndex} />)}
                </Slider>
            </ContentWrap>
            {!isMobile && (
                <>
                    <CharacterBox>
                        <img src={getImage(`slide_game_cha_${SLIDE_DATA[currentIndex].id}.png`)} alt=""/>
                    </CharacterBox>
                    {currentIndex === 3 &&
                        <span className={"dimBg"}>
                        <img src={comingSoonDeco} alt=""/>
                        <p className={"outlinedText"} data-text={"Coming Soon"}>Coming Soon</p>
                        <img src={comingSoonDeco} alt=""/>
                    </span>
                    }
                </>
            )}


        </SlideWrap>
    );
};

export default GameSlide;

const SlideWrap = styled.div`
  position: relative;
  margin-top: 50px;
  padding-bottom: 48px;
  .gameBgSlider {
    width: 100%;
    box-sizing: border-box;
    .slick-track {
      display: flex;
    }
    .slick-list {
      width: 1400px;
      height: 820px;
      border-radius: 10px;
    }
    
    .slick-dots {
      display: flex !important;
      justify-content: flex-start;
      gap: 5px;
      bottom: 32px;
      padding-left: 180px;
      z-index: 999;
      li {
        margin: 0;
        width: 108px;
        height: 134px;
        display: flex;
        justify-content: center;
        align-items: center;
        .customDots {
          position: relative;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          .dim {
            display: block;
            position: absolute;
            top: 54%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.6rem;
            color: ${theme.colors.accent};
            text-shadow: 0px 0px 8px rgba(254, 235, 141, 0.8);
            &:before {
              --stroke-color: #031012;
              --stroke-width: 2px;
            }
          }
        }
      }
    }
  }
  .dimBg {
    position: absolute;
    top: -24px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 38px;
    padding-top: 24px;
    width: 1400px;
    height: 844px;
    background: url(${slideDim}) no-repeat center center;
    background-size: cover;
    z-index: 99;
    
    img {
      width: 76px;
    }
    .outlinedText {
      font-size: 7.2rem;
      font-weight: 500;
      color: ${theme.colors.accent};
      text-shadow: 0px 0px 24px rgba(254, 235, 141, 0.9);
      &:before {
        --stroke-width: 8px;
      }
    }
  }
  @media ${theme.mq.desktop}{
    margin-top: 2.6042vw;
    padding-bottom: 2.5000vw;
    .gameBgSlider {
      .slick-list {
        width: 72.9167vw;
        height: 42.7083vw;
        border-radius: 0.5208vw;
      }
      .slick-dots {
        gap: 0.2604vw;
        bottom: 1.6667vw;
        padding-left: 9.375vw;
        li {
          width: 5.625vw;
          height: 6.9792vw;
          .customDots {
            .dim {
              font-size: 0.8333vw;
            }
          }

        }
      }
    }
    .dimBg {
      top: -1.2500vw;
      gap: 1.9792vw;
      padding-top: 1.2500vw;
      width: 72.9167vw;
      height: 43.9583vw;
      img {
        width: 3.9583vw;
      }
      .outlinedText {
        font-size: 3.75vw;
        text-shadow: 0.0000vw 0.0000vw 1.2500vw rgba(254, 235, 141, 0.9);
        &:before {
          --stroke-width: 0.4167vw;
        }
      }
    }
  }
  @media ${theme.mq.mobile}{
    width: 88.8889vw;
    height: 239.7222vw;
    margin-top: 0;
    .gameBgSlider {
      .slick-list {
        width: 88.8889vw;
        height: 239.7222vw;
        border-radius: 10px;
      }
      .slick-dots {
        width: auto;
        padding-left: 0;
        left: 50%;
        bottom: 4%;
        transform: translateX(-50%);
        gap: 1.6667vw;
        li {
          width: 18.3333vw;
          height: 19.4444vw;
          .customDots {
            .dim {
              font-size: 3.3333vw;
              &:before {
                --stroke-width: 3px;
              }
            }
          }
        }
      }
    }
    
  }
`;
const ContentWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1400px;
  height: 820px;
  background-image: url(${slideFrame});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: 99;
  border-radius: 0;
  .gameContentSlider {
    width: 100%;
    height: 100%;
    .slick-list {
      width: 100%;
      height: 100%;
      .slick-track{
        height: 100%;
        .slick-slide {
          >div {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .slick-dots {
      display: flex !important;
      justify-content: center;
      gap: 15px;
      bottom: -48px;
      z-index: 999;
      li {
        margin: 0;
        display: block;
        width: 16px;
        height: 16px;
          border: 2px solid ${theme.colors.btn};
        border-radius: 50%;
        overflow: hidden;
        &.slick-active {
            background-color: ${theme.colors.btn};
        }
        button {
          padding: 0;
          width: 100%;
          height: 100%;
          &::before {
            display: none;
          }
        }
      }
    }
  }
  
  @media ${theme.mq.desktop}{
    width: 72.9167vw;
    height: 42.7083vw;
    .gameContentSlider {
      
      .slick-dots {
        gap: 0.7813vw;
        bottom: -2.5vw;
        li {
          width: 0.8333vw;
          height: 0.8333vw;
          border-width: 0.1042vw;
        }
      }
    }
  }
  @media ${theme.mq.mobile}{
    width: 100%;
    height: 100%;
    border-radius: 1.1111vw;
    overflow: hidden;
    background-image: url(${slideFramM});
  }
  
`;

const SlideBgBox = styled.div`
  @media ${theme.mq.mobile}{
    width: 88.8889vw;
    height: 239.7222vw;
  }
`;


const CharacterBox = styled.div`
  position: absolute;
  bottom: 44px;
  right: 0;
  z-index: 99;
  height: 883px;
  img {
    height: 100%;
  }
  @media ${theme.mq.desktop}{
    right: 0;
    height: 45.9896vw;
    bottom: 2.2917vw;
    width: 60%;
    display: flex;
    justify-content: flex-end;
    img {
      width: unset !important;
    }
  }
`;