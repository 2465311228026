import styled from "styled-components";

const Body = styled.body`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: black;
  font-family: Inter;
  padding: 100px 20px;
`;

const BottomText = styled.div`
  page-break-after: always;
  font-weight: 300;
  line-height: 130%;
  font-weight: 400;
  span {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      padding: 0px;
    }
  }

  p {
    unicode-bidi: isolate;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  b {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
`;

const PageContainer = styled.div`
  page-break-before: always;
  page-break-after: always;
  font-weight: 300;
  line-height: 130%;
  font-weight: 400;
  max-width: 1000px;
  margin-bottom: 60px;
  width: 100%;
  span {
    display: flex;
    flex-direction: column;
    padding-top: 32px;

    p {
      padding: 0px;
      margin-bottom: 16px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  p {
    unicode-bidi: isolate;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }
  b {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  div {
    padding-top: 8px;
  }
  a {
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const CenterText = styled.p`
  text-align: center;
`;
const Title = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding-bottom: 80px;
`;
const Privacy = () => {
  return (
    <Body>
      <Title>Privacy Policy</Title>
      <PageContainer>
        <p>
          Carrieverse Co., Ltd. (the &#8220;Company&#8221;) recognizes the
          importance of the Users&#8217; personal information and complies with
          the personal information protection regulations under the relevant
          laws and regulations which the Company is required to comply with such
          as the &#8220;Act on the Promotion of Information and Communications
          Network Utilization and Information Protection, Etc.&#8221; and the
          &#8220;Personal Information Protection Act.&#8221; Through this
          Privacy Policy, the Company discloses to the Users as to how their
          personal information is used, and for what purposes, and what measures
          are taken to protect their personal information. <br />
          <span>
            In the event the Company revises this Privacy Policy, the Company
            will provide notice through posting announcements on its website (or
            by individual notification). If you have any inquiries about this
            Privacy Policy, the operation of our website or how to use our
            website, please contact our Personal Information Protection Manager
            by e- mail or by mail (3rd floor, 35-6, Samsung-ro 85gil,
            Gangnam-gu, Seoul).
          </span>
        </p>
        <div>
          <p>
            &#8226; Inquiries about In-Game
            <br /> &emsp;&#9702; E-mail : cs@carrieverse.com
          </p>
        </div>
        <div>
          <p>
            &#8226; Inquiries about NFT
            <br /> &emsp;&#9702; E-mail : support-clingswac@carrieverse.com
          </p>
        </div>
      </PageContainer>

      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>1. Personal Information Collected by the Company</b>
        </p>
        <p>
          There are three main ways that the Company may collect personal
          information:
          <span>
            (1) Collecting information that the User provides to the Company:
            <br />
            The User may provide the following information to the Company in
            order to play the Game and use our website.
            <br />
            <div>&#8226; Player Name</div>
            <div>
              &#8226; Contact Information (such as name and e-mail address)
            </div>
            <div>
              &#8226; Any information you submit to our customer service team in
              the Game or e-mail
            </div>
            <div>
              &#8226; Other data you choose to give us (such as data to identify
              a lost account)
            </div>
          </span>
        </p>

        <p>
          <span>
            (2) Collecting information automatically during the User&#8217;s use
            of our services: <br />
            The Company may automatically collect the following information
            during the User&#8217;s use of our services. The Company generally
            uses the following items of personal information for resolving
            technical issues or improving server environment and not for
            personal identification. <br />
            <div>
              &#8226; Your Service communications (e.g. in-game chats and
              messages with other players)
            </div>
            <div>
              &#8226; Data and analytics about your use of our Services (e.g.
              in-game play progress, levels, games played, session length,
              preferred settings, which in-game adverts you may have viewed,
              in-game preferences or just game preferences, which friends you
              connect with, the means by which you were referred to our
              Services)
            </div>
            <div>
              &#8226; Your IP address and device identifiers such as your
              Advertising ID, Device ID numbers
            </div>
            <div>
              &#8226; Data about your device, such as device name and operating
              system, browser type and language
            </div>
            <div>
              &#8226; General geographic location (e.g. country or city
              location) based on your IP address
            </div>
          </span>
        </p>

        <p>
          <span>
            &#8220;Advertising ID&#8221; means the Apple Identifier for
            Advertisers (&#8220;IDFA&#8221;) on Apple, and Google Advertising
            Identity (&#8220;GAID&#8221;). These are unique identifiers for
            mobile devices that advertisers use for interest-based advertising.
            They are consistent across all apps and thus allow cross-app
            tracking. On Apple, you can opt out by choosing the &#8220;limit ad
            tracking&#8221; option in the privacy settings. On Google Android,
            you can opt out by choosing the &#8220;Opt out of interest based
            ads.&#8221; Advertising IDs are non-permanent, non- personal
            identifiers uniquely associated with your device. Similar
            technologies include tracking pixels within ads. These technologies
            allow companies (including marketers and advertisers) to recognize
            your device when you use websites and applications. &#8220;Device
            identifier/information&#8221; means device type, device model,
            device manufacturer, device operating system and language, or other
            technical data like screen size, processor, or a combination of this
            data, available from a device for a developer to check the
            device&#8217;s compatibility with a particular application.
          </span>
        </p>
        <p>
          <span>
            <p>
              (3) Collecting information from third parties: The Company
              receives the following items of personal information from third
              parties to identify the Users. The Company receives such
              information in encrypted form.
            </p>

            <p>
              (a) Information from social network platforms. <br />
              You may choose to connect to our Services via your social network
              account. The exact information we receive from you will depend on
              your social media privacy settings, but it will typically include
              your basic public profile information, such as:
              <div>
                &#8226; your username, email, profile picture, your friends
                playing the same Game Typical uses include updating game
                progress on multiple devices you may own so that you can
                continue your progress using multiple devices. In addition, we
                may show your Facebook friends in the Game and show your public
                Facebook profile name and picture in Game leaderboards and in
                your Game profile.
              </div>
            </p>
            <p>
              (b) Payment When you purchase any products or services, such as
              Virtual Money or Virtual Goods, through our Services (which called
              &#8220;In-app-purchase&#8221;), we may collect certain payment
              information from you on behalf of our payment service providers.
              When you purchase our products or services through third party
              platforms (such as Apple App Store or Google Play Store), you will
              be required to provide certain payment and billing information
              directly to such third party platforms, and we do not collect or
              store any payment information from you.
            </p>
            <p>
              (c) Third party service providers. We may receive personal
              information about you from third-party sources. We may get your
              personal information, for example, when you installed our Game or
              when, prior to installation, you click on an advertisement for the
              Game.
            </p>
            <p>
              (d) Advertising partners. From time to time, we may also receive
              personal information about you from other third-party sources. For
              example, if you clicked on an advertisement directing you to one
            </p>
          </span>
        </p>
        <p>
          of our Services (such as an ad for one of our Games in another
          publishers&#8217; game), we will be provided with information about
          which ad network and advertising campaign the install originated from.
          You should ensure that you read their privacy policies to understand
          how they treat your data and what data they might share with us. For
          more information and opt-out possibilities, please visit the links
          below.
        </p>
        <p>
          <div>
            &#8226; Facebook
            <br />
            &emsp;
            <a href="https://www.facebook.com/about/privacy" target="_blank">
              https://www.facebook.com/about/privacy
            </a>
          </div>
          <div>
            &#8226; Google <br /> &emsp;
            <a href=" https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
          </div>
          <div>
            &#8226; AppLovin <br />
            &emsp;
            <a href=" https://www.applovin.com/privacy" target="_blank">
              https://www.applovin.com/privacy
            </a>
          </div>
          <div>
            &#8226; Unreal Engine <br />
            &emsp;
            <a
              href="https://www.epicgames.com/site/ko/privacypolicy"
              target="_blank"
            >
              https://www.epicgames.com/site/ko/privacypolicy
            </a>
          </div>
        </p>

        <p>
          <span>
            The Company may collect the User&#8217;s personal information to
            execute and perform contracts with the User (e.g. providing services
            pursuant to the User&#8217;s requests). If the User does not provide
            such personal information, the Company may restrict the supply of
            any services or goods that the User may request.
          </span>
          <span>
            (4) No special categories of information The Company does not
            request or intends to collect any &quot;special categories of
            information&quot; such as any information on health, race, religion,
            political opinions or philosophical beliefs, sexual preferences or
            orientation. Please, be cautious when sharing this information about
            yourself (or others) in our community.
          </span>
        </p>
      </PageContainer>
      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>
            2. Non-personally identifiable information automatically collected
            by the Company
          </b>
        </p>
        <p>
          The Company may collect the following non-personally identifiable
          information during the use of our services. Non-personally
          identifiable information means information that cannot be used to
          identify a specific user.
        </p>
      </PageContainer>
      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>3. Purpose of Processing Personal Information</b>
        </p>

        <p>
          The Company mainly processes the User&#8217;s personal information
          based on the following grounds.
        </p>
        <p style={{ marginLeft: "40px" }}>
          <div>
            &#9312; Where processing of personal information is necessary for
            the execution and performance of contracts: The Company processes
            the User&#8217;s personal information mainly to perform its duties
            and obligations under contracts entered into with the Users. If the
            User uses the Company&#8217;s services, the Company will use the
            User&#8217;s personal information to maintain the User&#8217;s
            account and profile, provide services and customer support, and
            allow the platform service provider to link the User&#8217;s global
            account to the platform.
          </div>
          <div>
            &#9313; Where the personal information is processed for the
            justifiable interests of the Company without prejudice to the
            User&#8217;s interests or basic rights: If there is a justifiable
            interest for the processing of personal information, the Company may
            use the User&#8217;s personal information. An example of a
            justifiable interest of the Company may include where the Company
            uses the personal information of the Users and visitors to carry out
            and improve its services and business activities. Further, the
            Company may analyze the User&#8217;s use behavior to continuously
            improve its services or suggest services that meet the User&#8217;s
            areas of interest and may use their personal information for legal
            purposes such as website management and prevention of illegal
            activities.
          </div>
          <div>
            &#9314; Where processing of personal information is necessary for
            the Company&#8217;s compliance with laws and regulations: For
            example, the Company may process the User&#8217;s personal
            information to comply with the accounting standards and regulations
            that require the retention of accounting records or to comply with
            disclosure obligations required by law enforcement agencies.
          </div>
          <div>
            &#9315; Where the User consents to the processing of his or her
            personal information by the Company: In certain cases, the Company
            may request the User&#8217;s consent to its use of his or her
            personal information. The User may withdraw his or her consent for
            the processing of personal information at any time through the
            contact person identified in the preamble to this Privacy Policy.
            Such withdrawal of consent shall have no effect on the legality of
            personal information processed prior to the withdrawal.
          </div>
        </p>
      </PageContainer>
      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>4. Purpose of Using Personal Information</b>
        </p>

        <p>
          In the event the Company entrusts the processing of personal
          information to third party service providers, such third party service
          provider may only process part of such personal information that are
          necessary to perform the services requested by the Company.
        </p>
        <p style={{ marginLeft: "40px" }}>
          <div>
            &#9312; Providing customer support services for receiving and
            responding to inquiries
          </div>
          <div>
            &#9313; Providing forum services within the Company&#8217;s website
          </div>
          <div>
            &#9314; Resolving technical issues and improving service
            environments
          </div>
          <div>&#9315; Identifying the Users to provide game services</div>
          <div>
            &#9316; Taking technical protective measures against the use of
            illegal programs
          </div>
          <div>
            &#9317; Conducting marketing and promotional activities such as
            providing information on events and surveys and providing
            opportunities to participate and providing promotional information
          </div>
          <div>
            &#9318; Analyzing service usage records and sign in frequency,
            collecting statistics on service usage and analyzing and collecting
            statistics about our services
          </div>
          <div>&#9319; To comply with our legal obligations</div>
          <div>
            &#9320; To exercise, establish or defend our legal rights, or to
            protect your vital interests or those of any other person 10.
          </div>
          <div>
            &#9321; To take action against fraudulent or misbehaving players
          </div>
        </p>
      </PageContainer>

      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>5. Sharing Personal Information with Third Parties</b>
        </p>

        <p>
          The Company may share the User&#8217;s personal information with third
          parties, including service providers who process the User&#8217;s
          personal information on behalf of the Company, to provide customer
          service and manage our communities, and detect and block use of
          hacking tools and unauthorized programs, subject to the requirements
          set forth in the personal information protection laws and regulations
          of the relevant country. The types of such third parties are as
          follows:
        </p>
        <p style={{ marginLeft: "40px" }}>
          <div>
            &#9312; Other users (for example, if the user voluntarily discloses
            his or her personal information through the Company&#8217;s
            services)
          </div>
          <div>
            &#9313; Business partners of the Company (for example, for the
            purpose of providing the Users with discounts or other benefits)
          </div>
          <div>
            &#9314; Customer service providers to provide customer support
            services
          </div>
          <div>
            &#9315; External platform service providers to link the User&#8217;s
            global account with the external platform
          </div>
          <div>
            &#9316; Blocking service providers to block third party programs
            such as hacking tools and unauthorized programs
          </div>
          <div> &#9317; Cloud service providers to provide cloud services</div>
          <div>
            &#9318; Payment settlement service providers to make payments for
            contract performance or collect payments from sellers
          </div>
          <div>
            &#9319; Marketing platform service providers to provide tailored
            services and user behavior analytics services providers
          </div>
          <div> &#9320; Survey service providers to improve game services</div>
        </p>
      </PageContainer>

      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>6. Sharing of Your Information</b>
        </p>

        <p>
          The Company does not share your personal information with third
          parties except as explicitly approved by you or except in the
          following circumstances:
          <span>(1) Third Party Service Providers.</span>
          <p>
            The Company may provide your information to third party companies
            (the &#8220;Third Party Service Providers&#8221;) that perform
            services on our behalf, including, without limitation, data
            analysis, marketing services, promotional events, email campaigns,
            hosting services, and customer service or support. The Third Party
            Service Providers may access your personal data, but are required to
            use it solely as directed by us for performing requested services.
            The Company will ask for your consent before The Company shares your
            data with the Third Party Service Providers.
          </p>
          <span>(2) Advertising of Third Party Products/Services.</span>
          <p>
            The Company will not actively share personal data with third party
            advertisers (the &#8220;Advertisers&#8221;) for their direct
            marketing purposes unless we obtain your consent. The Company may
            share (i) aggregated information (information about you and other
            Haegin users collectively, but not specifically identifiable to
            you); (ii) anonymous information; and (iii) certain technical
            information (including IP Addresses and mobile device IDs) to
            develop and deliver targeted advertising in the Advertiser&#8217;s
            website or application. The Company may also allow the Advertisers
            to collect these and similar types of information within the Service
            and they may share it with us and/or use it for advertising. The
            Advertisers may collect this information through the use of a
            variety of tracking technologies, including without limitation,
            browser cookies and web beacons. The information collected may be
            used to offer you targeted ad-selection and delivery in order to
            personalize your User experience by ensuring that advertisements for
            products and services you see will appeal to you, a practice known
            as behavioral advertising, and to undertake web analytics (i.e. to
            analyze traffic and other end user activity to improve your
            experience). The Company will ask for your consent before we share
            your personal data with the Advertisers.
          </p>
          <span>
            (3) Safety, Security and Compliance with Law. The Company may
            disclose your information to certain third parties if we reasonably
            believe that:
          </span>
        </p>

        <p>
          <div>
            &#8226; The Company is required to disclose the information in
            response to legal process (e.g., a court order, search warrant or
            subpoena);
          </div>
          <div>
            &#8226; The Company is required to satisfy any applicable laws,
            regulations, or administrative orders;
          </div>
          <div>
            &#8226; There is an emergency that poses a threat to the safety
            condition of you, another person, or general public; and
          </div>
          <div>
            &#8226; Such disclosure is reasonably necessary to protect the
            rights or property of Haegin.
          </div>
        </p>
        <p>
          <span>
            (4) Sale or Merger In the event that Haegin undergoes a business
            transition, such as a merger, acquisition by another company, or
            sale of all or the substantial portion of its assets, The Company
            may transfer all of your information you have provided, including
            personal data, to the successor organization in such transition if
            permitted by and done in accordance with any applicable law. If
            material changes to our privacy practices will occur as a result of
            the business transition, The Company will notify you the business
            transition prior to transferring your personal data.
          </span>
        </p>
      </PageContainer>

      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>7. Period of Retention of Personal Information</b>
        </p>

        <p>
          The Company retains personal information collect from you where we
          have an ongoing legitimate business need to do so. For example, to
          provide you with a Service you have requested, or to comply with
          applicable legal, tax or accounting requirements. For example, the
          Company periodically de-identifies unused Game accounts and regularly
          review and de-identifies unnecessary personal information.
          <span>
            When the Company have no ongoing legitimate business need to process
            your personal information, the Company will either delete or
            de-identify it, or, if this is not possible  – for example, because
            your personal information has been stored on a backup server  – then
            the Company will securely store your personal information and
            isolate it from any further processing until deletion is possible.
          </span>
          <span>
            Typically, the Company retain your information for 1 month after
            deregistration of your account or for the duration described below:
          </span>
        </p>

        <p>
          <div>
            &#8226; Records of customer complaint or dispute resolution: 3 years
          </div>
          <div>
            &#8226; Computer communication or internet log-in records of
            computer communication or facts about an internet user&#8217;s
            utilization of telecommunication service: 3 months
          </div>
          <div> &#8226; Tax and accounting records: 5 years</div>
          <div>
            &#8226; Records of electronic financial transaction: 5 years
          </div>
          <div>
            &#8226; Records of misuse by users of game services: 1 year{" "}
          </div>
        </p>
      </PageContainer>

      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>8. Age Limit</b>
        </p>

        <p>
          Children under a certain age, depending on where he or she lives (e.g.
          under 13 years in U.S. or under 16 years in EU), can only use our game
          services with a valid consent from their legal representative (e.g.
          parents). We make reasonable efforts to verify if consent from or any
          kind of contract concluded with such children is valid by offering
          users age verification methods and by obtaining clear consent from
          children&#8217;s legal representatives. If we learn that we have
          inadvertently gathered personal data from such children, we will take
          reasonable measures to promptly erase such information from our
          records. If children under that age or their legal representative ask
          us to cease processing or profiling of the child&#8217;s personal data
          for the purposes of direct marketing, we will immediately do so.
        </p>
      </PageContainer>
      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>9. Deletion of Personal Information</b>
        </p>

        <p>
          In principle, the Company will immediately remove the users&#8217;
          personal information after achieving the purpose for its processing.
          Personal information stored in physical form will be shredded or
          incinerated, and personal information stored in electronic file form
          will be removed by using technical measures that will prevent
          reproduction of the information. However, in the case of Paragraph 6
          of this Privacy Policy, &#8220;Period of Retention of Personal
          Information,&#8221; the Company may retain the User&#8217;s personal
          information as stated therein.
        </p>
      </PageContainer>

      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>10. User&#8217;s Rights and How to Use Them</b>
        </p>

        <p>
          1. In accordance with relevant laws and regulations, the User has the
          right to refuse or limit the processing of his or her personal
          information, and the right to view, correct, delete and transfer his
          or her personal information. If the User wishes to exercise such
          rights or has any inquiries about the personal information protection
          practices, please contact our Personal Information Protection Manager
          by e-mail (mantisco_privacy@imantisco.com) and the Personal
          Information Protection Manager will verify the User&#8217;s
          identification through reasonable means. The Company will use its
          efforts to respond when the User exercises his or her rights in
          accordance with laws and regulations related to personal information
          protection.
          <div>
            2. The User may use the Company&#8217;s website without providing
            his or her personal information. However, if the User does not
            consent to the use of his or her personal information, the User may
            not be able to use the Company&#8217;s game services, customer
            support services or forum services.
          </div>
        </p>
      </PageContainer>
      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>
            11. Matters on the Installation, Operation and Rejection of Personal
            Information Automatic Collection Equipment
          </b>
        </p>

        <p>
          The Company uses cookies that store and track User information.
          Cookies are small text files that are sent to the User&#8217;s web
          browser from the server used to operate this website and are stored on
          the User&#8217;s computer. The Company uses cookies for the following
          purposes.
        </p>
        <p>
          <span>
            1. Purposes of using cookies, etc.: Analyze log in frequency, time
            of visit, etc. by members and non-members, determine the
            User&#8217;s preferences and interests and track user&#8217;s
            movements, and provide targeted marketing and personalized services
            by verifying frequency of event participation and number of
            visitations. The User has the right to opt out from the installation
            of cookies. As a result, the User can accept all cookies, decide
            each time a cookie is stored or decline all cookies by selecting
            appropriate options on the User&#8217;s web browser&#8217;s
            settings.
          </span>
          <span>How to modify settings to decline cookies</span>
          <div>
            Example: To modify settings to decline cookies, the User may accept
            all cookies, decide each time a cookie is stored, or decline all
            cookies by selecting appropriate options on the User&#8217;s web
            browser settings. Example of modifying the User&#8217;s settings:
            For Internet Explorer, On the top of the User&#8217;s web browser
            select &#8220;Tools&#8221; &gt; &#8220;Internet Options&#8221; &gt;
            &#8220;Privacy&#8221; However, if the User declines all cookies, the
            User may not be able to fully use our services.
          </div>
        </p>
      </PageContainer>

      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>12. Protective Measures for Personal Information</b>
        </p>

        <p>
          The Company uses its best efforts to implement physical, technical and
          administrative protective measures to prevent the User&#8217;s
          personal information from being lost, stolen, leaked, altered or
          damaged. However, it is difficult to guarantee the full and complete
          security of all information. B y providing the User&#8217;s personal
          information to the Company, the User hereby acknowledges such
          difficulty and agree that his or her information is not guaranteed to
          be fully protected.
        </p>
        <p>
          <div style={{ marginLeft: "40px" }}>
            &#9312; Administrative protective measure Establish and implement
            internal management plan, regular employee training, supervision
            over compliance with security regulations by third party service
            providers, etc.
          </div>
          <div style={{ marginLeft: "40px" }}>
            &#9313; Technical protective measures
          </div>
          <div>
            Manage access rights to the personal information processing system,
            install and operate the access restriction system, encrypt personal
            information, install and operate security programs, etc.
          </div>
          <div style={{ marginLeft: "40px" }}>
            &#9312; Physical protective measure Restrict access to data
            processing room, data storage room, etc.{" "}
          </div>
          <div>
            Restrict access to data processing room, data storage room, etc.
          </div>
          <div style={{ marginLeft: "40px" }}>
            &#9312; Implementation and compliance with privacy policy
          </div>
        </p>
        <p style={{ marginTop: "8px" }}>
          The Company is always using its best efforts to correct any
          deficiencies in the event the Company discovers any issues with the
          implementation of this Privacy Policy or the Personal Information
          Protection Manager&#8217;s compliance herewith. However, the Company
          shall not be responsible for any disclosures of personal information
          such as passwords caused by the User&#8217;s own negligence or
          Internet problems. The Users are responsible for properly managing
          their accounts and passwords in order to protect their own personal
          information.
        </p>
      </PageContainer>
      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>13. Contacting the Personal Information Protection Manager </b>
        </p>

        <p>
          The Company designates the following person as its Personal
          Information Protection Manager to handle all comments and requests
          regarding personal information matters.
          <br />
          <span>
            Personal Information Protection Manager <br />
            Name : Bongseok Kang
            <br />
            Telephone : +82-2-564-0928
            <br />
            E-mail : business@carrieverse.com
          </span>
          <span>
            If you have any inquiries about complaints about infringement of
            personal information or require consultation, please contact the
            following institutions. <br />
            Personal Information Infringement Report Center
            (https://privacy.kisa.or.kr/ +82 118) <br />
            Personal Information Dispute Mediation Committee
            (https://www.kopico.go.kr/ +82 2- 2100-2499) <br />
            The Supreme Public Prosecutors Office, Cyber Crime Investigation
            Division (https://www.spo.go.kr/ +82 2-3480-3571) <br />
            The Police Headquarters, Cyber Security Bureau
            (https://cyberbureau.police.go.kr/ +82 182 )
          </span>
        </p>
      </PageContainer>

      <PageContainer>
        <p style={{ marginBottom: "16px" }}>
          <b>14. Notification and Revision of Privacy Policy</b>
        </p>

        <p>
          This Privacy Policy may be found on the Company&#8217;s website at any
          time. In the event the Company changes this Privacy Policy, the
          Company shall announce such changes by stating the effective date and
          the changed contents in accordance with the methods stipulated in the
          relevant laws and regulations. Prior versions of this Privacy Policy
          may be viewed through the &#8220;Privacy Policy Revisions&#8221; at
          the bottom of this webpage.
        </p>
      </PageContainer>
      <PageContainer style={{ marginBottom: "80px" }}>
        <p style={{ marginBottom: "16px" }}>
          <b>15. Link</b>
        </p>

        <p>
          The Service may contain links to other websites. The fact that the
          Service links to a website or presents a banner ad or other type of
          advertisement does not mean that we endorse or authorize that third
          party, nor is it a representation of our affiliation with that third
          party. The Company is in no way responsible for the privacy policies
          of these other websites to which you choose to access from the
          Service. It is your responsibility to read the privacy policies of
          these other websites when exiting the Service.
        </p>
      </PageContainer>
      <BottomText>
        <CenterText>
          <b>&lt;Addendum&gt;</b>
        </CenterText>
        <p style={{ textAlign: "center", paddingTop: "8px" }}>
          This Privacy Policy shall take effect as of May 18, 2023.
        </p>
      </BottomText>
    </Body>
  );
};

export default Privacy;
