import React from 'react';
import styled from 'styled-components';
import {
  blingBgM,
  carrieFrame,
  carrieFrameM,
  frameDecoM,
  tokenBg,
  tokenBgM,
  tokenFrame,
} from '../../assets/images';
import Title from '../../components/Title';
import SubTitle from '../../components/SubTitle';
import { theme } from '../../styles/theme';
import { BTN_DATA, TOKEN_DATA } from './data';
import { useMediaQuery } from 'react-responsive';
import Btn from '../../components/Btn';

const Tokenomics = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });
  return (
    <TokenomicsWrap id={'4'}>
      <img
        src={isMobile ? tokenBgM : tokenBg}
        alt=""
        style={isMobile ? { opacity: '0.4', height: '546vw' } : {}}
      />
      <ContentWrap>
        <Title>Tokenomics</Title>
        <TokenWrap>
          <SubTitle>Tokenomics</SubTitle>
          <CardWrap>
            {TOKEN_DATA.map(({ title, imgSrc, description, id }) => (
              <CardBox key={id}>
                <ImgWrap>
                  <img src={imgSrc} alt={title} />
                </ImgWrap>
                <TextWrap>
                  <p className={'outlinedText'} data-text={title}>
                    {title}
                  </p>
                  <span>{description}</span>
                </TextWrap>
              </CardBox>
            ))}
          </CardWrap>
        </TokenWrap>
        <CarrieWrap>
          <SubTitle>CARRIEVERSE</SubTitle>

          <CarrieBox>
            <CarrieText>
              CVTX is a native token to play and own assets in CARRIEVERSE and{' '}
              <br className={'onlyPc'} />
              SUPERKOLA TACTICS. <br className={'onlyMobile'} />
              By optimizing the two tokens ($CVTX & $ERDL), SUPERKOLA TACTICS
              aims at the ecosystem’s sustainability and scalability. <br />
              For players to take home the rewards, utilize the tokens, thus
              creating an <br className={'onlyPc'} />
              internal circulating economy.
            </CarrieText>
            <BtnWrap>
              {BTN_DATA.map((btn) => (
                <Btn key={btn.id} imgSrc={btn.imgSrc} linkUrl={btn.link}>
                  {btn.title}
                </Btn>
              ))}
            </BtnWrap>
          </CarrieBox>
        </CarrieWrap>
      </ContentWrap>
    </TokenomicsWrap>
  );
};

export default Tokenomics;

const TokenomicsWrap = styled.div`
  position: relative;
  text-align: center;
  max-height: 1880px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
  @media ${theme.mq.mobile} {
    max-height: unset;
  }
`;
const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 120px;
  width: 100%;

  @media ${theme.mq.desktop} {
    padding-top: 6.25vw;
  }
  @media ${theme.mq.mobile} {
    padding-top: 20vw;
  }
`;
const TokenWrap = styled.div`
  margin-bottom: 144px;
  @media ${theme.mq.desktop} {
    margin-bottom: 7.5vw;
  }
  @media ${theme.mq.mobile} {
    margin-bottom: 25.5556vw;
  }
`;
const CardWrap = styled.div`
  display: flex;
  gap: 24px;
  padding-top: 31px;

  @media ${theme.mq.desktop} {
    gap: 1.25vw;
    padding-top: 1.6146vw;
  }
  @media ${theme.mq.mobile} {
    flex-direction: column;
    padding-top: 3.6111vw;
    gap: 13.3333vw;
  }
`;
const CardBox = styled.div`
  position: relative;
  padding-top: 73px;
  background: url(${tokenFrame}) no-repeat center center;
  background-size: cover;
  width: 320px;
  height: 490px;

  @media ${theme.mq.desktop} {
    padding-top: 3.8021vw;
    width: 16.6667vw;
    height: 25.5208vw;
  }
  @media ${theme.mq.mobile} {
    display: flex;
    gap: 8.3333vw;
    padding: 6.3889vw 0 6.3889vw 6.3889vw;
    flex-direction: row;
    align-items: center;
    width: 88.8889vw;
    height: auto;
    background: url(${blingBgM}) no-repeat left center;
    background-size: cover;
    border-top: 0.5556vw solid #fde6b5;
    border-bottom: 0.5556vw solid #fde6b5;
    &:before,
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 17.7778vw;
      height: 6.6667vw;
      left: 50%;
      transform: translateX(-50%);
      background: url(${frameDecoM}) no-repeat center center;
      background-size: cover;
    }
    &:before {
      top: -3.8889vw;
    }
    &:after {
      bottom: -3.8889vw;
      transform: scale(-1) translateX(50%);
    }
  }
`;
const ImgWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 132px;
  transform: translateX(-50%);
  width: 200px;
  img {
    width: 100%;
  }
  @media ${theme.mq.desktop} {
    width: 10.4167vw;
    top: 6.875vw;
  }
  @media ${theme.mq.mobile} {
    position: static;
    transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26.6667vw;
    height: 26.6667vw;
    img {
      width: 115%;
    }
  }
`;
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 234px;
  .outlinedText {
    font-size: 3.2rem;
    &::before {
      --stroke-color: #173c46;
    }
  }
  span {
    font-size: 2.4rem;
    line-height: 1.33;
    width: 260px;
    white-space: pre-line;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
  }
  @media ${theme.mq.desktop} {
    gap: 12.1875vw;
    .outlinedText {
      font-size: 1.6667vw;
    }
    span {
      font-size: 1.25vw;
      width: 13.5417vw;
    }
  }
  @media ${theme.mq.mobile} {
    padding-top: 2.2222vw;
    gap: 4.4444vw;
    flex: 1;
    align-items: flex-start;
    text-align: left;
    .outlinedText {
      font-size: 5vw;
    }
    span {
      font-size: 4.4444vw;
      width: 42.2222vw;
      line-height: 1.62;
    }
  }
`;

const CarrieWrap = styled.div`
  @media ${theme.mq.mobile} {
    margin-bottom: 15.5556vw;
  }
`;
const CarrieBox = styled.div`
  margin-top: 32px;
  padding: 68px 72px;
  width: 1400px;
  height: 562px;
  background: url(${carrieFrame}) no-repeat center center;
  background-size: cover;

  @media ${theme.mq.desktop} {
    margin-top: 1.6667vw;
    padding: 3.5417vw 3.75vw;
    width: 72.9167vw;
    height: 29.2708vw;
  }
  @media ${theme.mq.mobile} {
    margin-top: 0;
    padding: 8.8889vw;
    width: 88.8889vw;
    height: 272.2222vw;
    background: url(${carrieFrameM}) no-repeat center center;
    background-size: cover;
  }
`;
const CarrieText = styled.p`
  margin-bottom: 40px;
  width: 904px;
  text-align: left;
  color: ${theme.colors.darkText};
  font-size: 2.4rem;
  line-height: 1.75;

  @media ${theme.mq.desktop} {
    margin-bottom: 2.0833vw;
    width: 47.0833vw;
    font-size: 1.25vw;
  }
  @media ${theme.mq.mobile} {
    margin-bottom: 10vw;
    width: 100%;
    font-size: 4.4444vw;
    line-height: 1.75;
  }
`;
const BtnWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 264px);
  align-items: start;
  gap: 16px;
  @media ${theme.mq.desktop} {
    grid-template-columns: repeat(3, 13.75vw);
    gap: 0.8333vw;
  }
  @media ${theme.mq.mobile} {
    display: flex;
    width: unset;
    height: unset;
    flex-direction: column;
    align-items: center;
    gap: 3.3333vw;
  }
`;
