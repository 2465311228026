import React from "react";
import styled from "styled-components";

const Body = styled.body`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: black;
  font-family: Inter;
  padding: 100px 20px;
`;

const BottomText = styled.div`
  page-break-after: always;
  font-weight: 300;
  line-height: 130%;
  font-weight: 400;
  span {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      padding: 0px;
    }
  }

  p {
    unicode-bidi: isolate;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  b {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
`;

const PageContainer = styled.div`
  page-break-before: always;
  page-break-after: always;
  font-weight: 300;
  line-height: 130%;
  font-weight: 400;
  max-width: 1000px;
  margin-bottom: 60px;

  span {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      padding: 0px;
    }
  }

  p {
    padding-top: 16px;
    unicode-bidi: isolate;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  b {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
`;

const CenterText = styled.p`
  text-align: center;
`;
const Title = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding-bottom: 80px;
`;

const Terms = () => {
  return (
    <>
      <Body>
        <Title>Terms of Service</Title>
        <PageContainer>
          <div>
            <b>Article 1 &#12304;Purpose and Definition&#12305;</b>
            <p>
              SUPERKOLA TACTICS(the &#8220;Game&#8221;) is a game developed by
              Newavel, Co., Ltd. (the &#8220;Developer&#8221;) and serviced by
              Carrieverse, Co., Ltd. (the &#8220;Publisher&#8221;). The Company
              provides this website (http://carrieverse.io) and the services
              related to the Game (collectively, the &#8220;Services&#8221;).
              The User is a person who agrees to the Terms of Service and uses
              the Services. By agreeing to the Terms of Service, the User
              indicates that he or she is thirteen (18) years old or older. If
              the User is between the ages of 13 and 18 or otherwise do not have
              the authority to enter into agreements such as these TOS, the User
              represents that their legal guardian, or a holder of parental
              responsibility, has reviewed and agreed to these TOS. If the User
              accesses the Service from a Social Networking Service
              (&quot;SNS&quot;), such as Facebook or Google+, the User shall
              comply with its terms of service/use as well as these Terms of
              Service. By using or otherwise accessing the Services, the User
              agrees to these TOS. If the User does not agree to these TOS, the
              User may not use or otherwise access the Services.
            </p>
          </div>
        </PageContainer>
        <PageContainer>
          <b>
            Article 2 &#12304;Specification and Amendment of the Terms of
            Service&#12305;
          </b>
          <p>
            <span>
              1. The Terms of Service can be accessed on the in-game or
              connected screens and official community at any time.
            </span>
            <span>
              2. The Company may change the Terms of Service to the extent
              necessary unless it violates applicable laws and regulations. In
              the event that the Company changes the Terms of Service, the
              changes shall be posted, along with the effective date and the
              details of the amendment, on the Company&#8217;s website at least
              seven (7) days prior to the effective date. Any changes that may
              be material or disadvantageous to the User shall be posted at
              least thirty (30) days prior to the effective date.
            </span>
            <span>
              3. Any User that does not express his or her rejection of the
              amended Terms of Services after the effective date hereof shall
              constitute the User&#8217;s agreement to the amended Terms of
              Service. The User is responsible for checking the Company&#8217;s
              website regularly to make note of any changes.
            </span>
          </p>
        </PageContainer>
        <PageContainer>
          <b>Article 3 &#12304;Rules Other Than the Terms of Service&#12305;</b>
          <p>
            <span>
              {" "}
              1. The Company may delegate specific matters that are not covered
              by the Terms of Service to the Operation Policy for purposes of
              protecting the rights and interests of the Users and maintaining
              order in the Game.
            </span>
            <span>
              2. The Operation Policy constitutes a part of the Terms of
              Service, and the User is deemed to have agreed to the Operation
              Policy by agreeing to the Terms of Service.{" "}
            </span>
            <span>
              3. The Operation Policy may be found on the Company&#8217;s
              website at any time.
            </span>
            <span>
              4. Any change to the Terms of Service that causes material change
              in the User&#8217;s rights or obligations or any amendment to the
              Operation Policy that has the same effect as the change to the
              Terms of Service shall be made pursuant to Article 2, paragraph 2.
            </span>
          </p>
        </PageContainer>
        <PageContainer>
          <div>
            <b>Article 4 &#12304;Prohibited Acts&#12305; </b>
            <p>
              The Company provides the Services to the Users only for personal,
              non-commercial, and entertainment purposes. The User agrees that
              he or she will not commit the following acts. Committing any of
              the following acts may lead to temporary or permanent suspension
              of the Services depending on the severity of the act in accordance
              with the Terms of Service and the Code of Conduct.
              <br />
              <span>
                1. Selling, renting, leasing or transferring, establishing
                security rights upon, making copies of the Game or any account
                related thereto, and transferring any portion thereof to any
                third party;
              </span>
              <span>
                2. Making copies of the Game or any part thereof, reproducing,
                translating, reverse- engineering, decompiling or deriving
                source code, disassembling, or creating derivative works based
                on the Game;
              </span>
              <span>
                3. Modifying or committing an act that leads to modifying any
                files included in the Game;
              </span>
              <span>
                4. Using or enabling any third party to use the Game or any of
                its parts commercially, including but not limited to selling the
                in-game items outside of the Game, unless otherwise approved by
                the Company and Developer or made through the Services provided
                by the Company;
              </span>
              <span>
                5. Producing, distributing, using, or advertising any programs
                that intercept communication between the Game and the game
                server and any unauthorized third party programs or hardware
                devices that may affect the Game or normal game play, including
                but not limited to, programs, hacks, cheats, bots, scripts,
                trainers, automatic programs, automatic mouse, mouse with macro
                functions that collect or modify game data by reading the game
                memory;
              </span>
              <span>
                6. Stealing information from others (including but not limited
                to personal, non-personal, and payment information);
              </span>
              <span>
                7. Impersonating any officers or employees, operators, or any
                other interested parties of the Company and the Developer;
              </span>
              <span>
                8. Harming the reputation or obstructing businesses of the
                Company and the Developer or any third party;
              </span>
              <span>
                9. Posting or making public on the Company&#8217;s website any
                sexually explicit or violent statement, video, sound or
                information that is against the public order and good morals;
              </span>
              <span>
                10. Making undue profit by exploiting other Users in any way;
              </span>
              <span>
                11. Using the Services in a manner related to gambling or that
                is unwholesome;
              </span>
              <span>12. Exploiting the bugs in the program or ;</span>
              <span>
                13. Committing any act that violates any other applicable laws
                or is against public morals or social norms
              </span>
            </p>
          </div>
        </PageContainer>
        <PageContainer>
          <p>
            <b>
              Article 5 &#12304;Accessing and Restricting Access to the
              Game&#12305;
            </b>
            <span>
              1. Unless otherwise indicated or notified, the Game will be
              available 24 hours a day, 7 days a week.
            </span>
            <span>
              2. Notwithstanding the foregoing paragraph 1, the Company may
              restrict or suspend access to all or a part of the Services
              without prior notice for a certain period of time under any of the
              following circumstances. However, if the Services are to be ceased
              in accordance with foreseeable technical or operational needs, the
              Company may cease the Services after posting a 30 days&#8217;
              prior notice on its website. Provided, however; under unavoidable
              circumstances, the Company may cease the Services without prior
              notice.
              <p>
                &#9312; In the event of repair and maintenance, inspection,
                replacement, periodic inspection of information and
                communications facilities, including but not limited to
                computers and server equipment, or for purposes of game
                operation;
              </p>
              <p>
                &#9313; In responding to unforeseen instability of the Services
                due to hacking incidents, network failures, service equipment
                failures, or irregular uses of the Game by the User;
              </p>
              <p>
                &#9314; In the event where the access to the Game is restricted
                pursuant to applicable laws and regulations, policies of the
                government or the Company;
              </p>
              <p>
                &#9315; In the event where the access to the game is obstructed
                due to an event of force majeure, including but not limited to
                the acts of God, states of emergencies, power failures, etc.; or
              </p>
              <p>
                &#9316; Under material management needs of the Company,
                including but not limited to split- off, merger, transfer of
                business, termination of business, profit decrease of the Game,
                etc. 3. The Company shall not be liable for the damages that may
                arise as a result of restriction, discontinuance, or suspension
                of the Services in accordance with paragraph 2 hereof unless
                there is any intentional act or gross negligence on the part of
                the Company.
              </p>
            </span>
          </p>
        </PageContainer>
        <PageContainer>
          <b>Article 6 &#12304;Ownership&#12305;</b>
          <p>
            <span>
              1. All titles and authorities, rights, related trademarks, logos
              and domains, trade dresses, and contents related to the Game
              (including but not limited to computer codes, titles, characters,
              names of characters, locations, names of locations, stories,
              storylines, artwork, graphics, designs of structures or scenes,
              animations, sounds, music, audio-visual effects) shall be owned by
              the Developer. The Developer shall have the exclusive rights to
              create any derivative works of the Game. The User agrees that he
              or she will not create any derivative works based on the Game
              unless otherwise explicitly permitted by the Developer. Any
              further matters in relation to the contents produced based on the
              Game shall be covered by the User-Created Content Rules posted on
              the Company&#8217;s website.
            </span>
            <span>
              2. The Developer shall not authorize any party to use any
              trademarks, logos, domains, trade dresses, or contents without its
              permission. Any violation thereof may result in severe civil or
              criminal penalties.
            </span>
            <span>
              3. The Developer has ownership, licensing rights and all other
              rights to all contents related to the Game. The User has the right
              to use the virtual items, goods, currency, game money, or any
              similar contents that appear or are generated in the Game in
              accordance with the Terms of Service. However, the User shall not
              have ownership or own copyrights or any other rights thereto. Any
              transfer or sale, gift, or trade of the in-game virtual property
              outside of the Game shall not be acknowledged unless otherwise
              approved by the Developer in writing. Therefore, the User may not
              sell or trade the in-game items, goods, or currency in exchange
              for actual currency without the Developer&#8217;s approval, and
              any violations thereof may be subject to penalties in accordance
              with the Terms of Service and the Operation Policyt.
            </span>
          </p>
        </PageContainer>
        <PageContainer>
          <b>Article 7 &#12304;Refund Policy&#12305;</b>
          <p>
            In general, all sales for all paid contents are final and refunds
            are not permitted except with respect to any statutory warranties
            that are mandatory by law or the third party platform&#8217;s refund
            policy. In case of termination due to the breach of these Terms of
            Service, the User shall not be entitled to any refunds.
          </p>
        </PageContainer>
        <PageContainer>
          <b>Article 8 &#12304;Purchase and Usage of NFTs&#12305;</b>
          <p>
            Users may purchase and utilize blockchain-based NFTs only if they
            are accessing the Game from jurisdictions that allow such
            activities. Users are solely responsible for complying with all the
            laws and regulations of their respective jurisdictions. The User may
            purchase NFTs from external sources and introduce them into the
            Game. However, the Game shall not offer the option to directly
            purchase NFTs in-game. The Company may incorporate NFTs into the
            Game&#8217;s structure. Any NFT introduced into the Game by the User
            shall be owned by the User. The responsibility to manage such NFTs
            shall also be borne entirely by the User. The User agrees to use
            introduce NFTs into the Game after confirming and understanding the
            risk that may occur when holding NFTs as follows. The Company shall
            not be liable to the User for any of the following.{" "}
            <span>
              1. No specific entity, including the Company, guarantees the value
              of NFTs, and the User bear all risks, including losses, that may
              arise from owning NFTs.
            </span>
            <span>
              2. The transfer of NFTs are conducted on their respective
              blockchain, and may be adversely affected by interruptions in the
              network. The Company is not the operator of the NFT blockchains
              and is not responsible for any problems.
            </span>
            <span>
              3. Due to the nature of NFTs, which are blockchain-based tokens
              assigned unique identification codes and metadata, the Company
              does not guarantee the security and integrity of NFTs introduced
              into the Game.
            </span>
            <span>
              4. The User is responsible for checking and verifying network
              support, compatibility, when introducing NFTs into the Game. The
              Company does not guarantee that any particular NFT will be
              supported by the Game. The User bears all responsibility for
              checking the support before making any transfers.
            </span>
          </p>
        </PageContainer>
        <PageContainer>
          <b>Article 9 &#12304;Trading in-Game Items&#12305;</b>
          <p>
            Users may exchange in-Game items for virtual assets only if they are
            accessing the Game from jurisdictions that allow such activities.
            Users are solely responsible for complying with all the laws and
            regulations of their respective jurisdictions. The User agrees to
            exchange in-Game items for virtual assets after confirming and
            understanding the risk that may occur. The Company shall not be
            liable to the User for any of the following.
            <span>
              1. The Company does not guarantee the value of tokens and coins.
              Digital assets, and blockchain technology are relatively new and
              their regulation is not clearly defined. New regulations may
              adversely affect blockchain technology, which may negatively
              affect the value of such assets.
            </span>
            <span>
              2. The Company shall not be responsible for any errors
              attributable to the User when executing the above-mentioned
              exchanges such as mistakenly entering wallet addresses or amount
              of tokens to be transferred
            </span>
          </p>
        </PageContainer>
        <PageContainer>
          <b>Article 10 &#12304;Indemnifications&#12305;</b>
          <p>
            The User agrees to defend, protect, indemnify, and hold harmless the
            Company from any claims, lawsuits, damages, losses, liabilities, and
            expenses (including reasonable attorney’s fees to the extent
            permitted by applicable laws) arising out of any misuse of the
            Services or violation of the Terms of Service by the User. The
            Company may, at its reasonable discretion, reserve the right to
            exclusively defend and control over all matters subject to
            indemnification by the Users, in which case, the User shall
            cooperate with the Company’s effort to secure its defense.
            <span>
              1. The Company shall be exempted from any and all liability for
              any suspension of the Service, the interruption of use,
              termination of contract due to the causes attributable to the
              User.
            </span>
            <span>
              2. The Company shall be exempted from any and all liability
              incurred to the User due to discontinuance or failure of normal
              operation of telecommunication services by the telecommunications
              service providers unless there is any intentional act or gross
              negligence on the part of the Company.
            </span>
            <span>
              3. The Company shall be exempted from any and all liability
              incurred from discontinuance or failure of the Services due to
              unavoidable reasons such as maintenance, replacement, periodic
              inspection, construction, etc. of the service equipment as posted
              unless there is any intentional act or gross negligence on the
              part of the Company.
            </span>
            <span>
              4. The Company shall be exempted from any and all liability
              incurred from overall problems that arise due to the User’s
              computer or network environment unless there is any intentional
              act or gross negligence on the part of the Company.
            </span>
            <span>
              5. The Company shall be exempted from any and all liability for
              the contents, such as reliability and accuracy of the information,
              data, and/or facts posted or transmitted by the User or a third
              party on or from the Company’s website unless there is any
              intentional act or gross negligence on the part of the Company.
            </span>
            <span>
              6. The Company is not obliged to become involved or be liable in
              any way for the disputes that arise between the Users or between
              the User and a third party in relation to the Services.
            </span>
            <span>
              7. The Company shall not be liable for any and all Services that
              have been provided free of charge unless there is any intentional
              act or gross negligence on the part of the Company.
            </span>
            <span>
              8. A portion of the Services may be provided by other service
              providers, and the Company shall be exempted from any and all
              liability for the damages caused by other service providers unless
              there is any intentional act or gross negligence on the part of
              the Company.
            </span>
            <span>
              9. The Company shall not be liable in any way for the User’s
              failure to receive expected results such as in-game characters or
              items, and the Company shall be exempted from any and all
              liability for the losses incurred due to selection of certain
              services unless there is any intentional act or gross negligence
              on the part of the Company.
            </span>
            <span>
              10. The Company shall be exempted from any and all liability for
              the losses of in-game cyber assets, items, game money of the User
              unless there is any intentional act or gross negligence on the
              part of the Company.
            </span>
            <span>
              11. The Company may limit the hours of use of the Services
              depending on the Users pursuant to relevant laws, government
              policies, etc. Unless there Is any intentional act or gross
              negligence on the part of the Company in applying such
              restrictions pursuant to relevant laws, the Company shall be
              exempted from any and all liability for overall matters related to
              such restrictions thereof.
            </span>
          </p>
        </PageContainer>

        <PageContainer>
          <b>Article 11 &#12304;Disclaimer of Warranties&#12305;</b>
          <p>
            The Game is provided on an &#8220;AS IS&#8221; and &#8220;AS
            AVAILABLE&#8221; basis without any express or implied warranties or
            representations. Unless there is any intentional act or gross
            negligence on the part of the Company, the Company hereby disclaims
            all express and implied warranties of any kind that may apply to the
            Game, including but not limited to the implied warranties of title,
            non-infringement, merchantability, fitness for particular purpose,
            the warranties that may arise during the course of transactions,
            implementation or use of transactions, the warranties for accuracy,
            reliability or quality of the contents and information included in
            the Game. The Company does not represent, warrant or guarantee that
            the Game will be provided uninterrupted, error-free, virus-free,
            free from other harmful factors, or that defects will be corrected.
            Notwithstanding the foregoing, the Company will make commercially
            reasonable efforts to resolve any issues related to the above. The
            User shall select the Game in order to obtain the intended result
            and be liable for all matters from installation, use and results
            thereof unless there is intentional act or gross negligence on the
            part of the Company. Some states or jurisdictions do not allow the
            disclaimer of implied warranties; therefore, all or part of the
            disclaimers may not be applicable to the User.
          </p>
        </PageContainer>
        <PageContainer>
          <b>Article 12 &#12304;Limitation of Liability&#12305; </b>
          <p>
            In no event shall the Company be liable for any indirect,
            incidental, consequential, special, exemplary, or punitive damages
            (including but not limited to the loss of business, loss of data,
            loss of goodwill, loss of profits) and damage (including but not
            limited to the interruption of work operation, hardware breakdown or
            malfunction, other commercial damage or loss) arising out of the
            User&#8217;s misuse unless there is intentional act or gross
            negligence on the part of the Company, and this is also true whether
            or not the Company already knew or had to know about the possibility
            of such damages. The Company shall not be liable for any
            interruption of service, including but not limited to ISP failure,
            software or hardware failure, or any other circumstances that may
            result in data loss or interruption of Services unless there is any
            intentional act or gross negligence on the part of the Company.
          </p>
        </PageContainer>
        <PageContainer>
          <b>Article 13 &#12304;Agreement Term and Termination&#12305;</b>
          <p>
            The Terms of Service continue to be effective unless and until
            terminated. The User may terminate the Terms of Service at any time.
            The User may terminate the Terms of Service by discontinuing the use
            of the Game and shall stop using and delete the Game immediately
            after the termination of the Terms of Service. If the User
            terminates the Terms of Service, refund policies shall be determined
            statutory warranties that are mandatory by law or the third party
            platform&#8217;s refund policy. The Company may terminate the Terms
            of Service and delete the User&#8217;s account with prior
            notification if the User violates their obligations under the Terms
            of Service. However, if the User has caused material damages to the
            Company due to intentional acts or gross negligence, the Company may
            terminate without prior notice.
          </p>
        </PageContainer>
        <PageContainer>
          <b>Article 14 &#12304;Transfer of the Terms of Service&#12305;</b>
          <p>
            The Company may transfer all or part of the Terms of Service to any
            third party at any time. The User shall not transfer the Terms of
            Service without the Company&#8217;s prior written consent, and any
            unauthorized transfer by the User shall be invalid.
          </p>
        </PageContainer>
        <PageContainer>
          <b>Article 15 &#12304;Force Majeure&#12305;</b>
          <p>
            The Company shall not be liable for any damages incurred from any
            and all delays or non- performances due to events beyond the
            reasonable control of the Company (including but not limited to acts
            of God, terrorism, riots, embargoes, acts of civil or military
            authorities, fire, floods, labor disputes, shortage of
            transportation facilities, fuel, energy, labor or materials).
          </p>
        </PageContainer>
        <PageContainer>
          <b>
            Article 16 &#12304;Grievance Handling and Dispute Resolution&#12305;
          </b>
          <p>
            <span>
              1. The Company should inform on the Company&#8217;s website about
              the means of presenting the opinions or complaints of the Users,
              considering their convenience.
            </span>
            <span>
              2. If a dispute arises between the Company and the User and a
              third party dispute resolution authority becomes involved, the
              Company shall faithfully demonstrate the measures taken on the
              User, including restrictions of use, and may comply with the
              resolution of the authority.
            </span>
          </p>
        </PageContainer>
        <PageContainer>
          <b>Article 17 &#12304;Governing Law&#12305;</b>
          <p>
            These Terms of Service shall be interpreted and governed by the laws
            of the Republic of Korea. All lawsuits related to the Terms of
            Service shall be subject to the jurisdiction of the competent courts
            pursuant to relevant laws such as the Code of Civil Procedure.
          </p>
        </PageContainer>
        <PageContainer style={{ marginBottom: "80px" }}>
          <b>Article 18 &#12304;</b>
          <b>Information Collection&#12305;</b>
          <p>
            <span>
              1. In order to maintain a smooth gaming environment, the company
              may store and retain users' chat log exclusively within the
              company.
            </span>
            <span>
              2. To prevent abusing, the company may collect users' data, and
              such data is held solely by the company.
            </span>
            <span>
              3. For stable game operation and service provision, the company
              may collect and utilize users' device information (settings,
              specifications, operating system, version, etc.)
            </span>
          </p>
        </PageContainer>
        <BottomText>
          <CenterText>
            <b>&lt;Addendum&gt;</b>
          </CenterText>
          <p style={{ textAlign: "center", paddingTop: "8px" }}>
            These Terms of Service shall take effect as of May 18, 2023.
          </p>
        </BottomText>
      </Body>
    </>
  );
};

export default Terms;
