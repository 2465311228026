import styled from 'styled-components';
const data = [
  {
    type: 'CommonCard',
    parts: [
      'Healing',
      'Strength',
      'Sharpness',
      'Haste',
      'Defeasance',
      'Expand Opportunities ',
      'Barrier',
    ],
    probabilities: [
      '5.00%',
      '5.00%',
      '5.00%',
      '5.00%',
      '5.00%',
      '5.00%',
      '5.00%',
    ],
  },
  {
    type: 'Warrior Card',
    parts: [
      'Power Lifting',
      'Sword Bomb',
      'Third Slash',
      'Slap Attack ',
      'Whirl Wind ',
      'Line Spear',
      'Front Blast',
      'Spinning Blade',
      'Madness ',
    ],
    probabilities: [
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
    ],
  },
  {
    type: 'MagicianCard',
    parts: [
      'Frost Shield',
      'Snow Wind',
      'Magic Missile',
      'Black hole',
      'Lightning Storm ',
      'Reverse Gravity ',
      'Return',
      'Triple Shot',
      'Magic Emission',
      'Tattoo Parts - 10',
      'Tattoo Parts - 11',
    ],
    probabilities: [
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
    ],
  },
  {
    type: 'Hunter Card ',
    parts: [
      'Traping ',
      'Shadow Pang',
      'Knife Storm',
      'Concentrated Fire',
      'Arrow Hale',
      'Arrow Rain',
      'Evasive Maneuver',
      'Jump Shot',
      'Barrage',
    ],
    probabilities: [
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
      '2.4074%',
    ],
  },
];

const CardSum = () => {
  return (
    <PageContainer>
      <TitleContainer>
        <Title>Card summoning probability information</Title>
        <SubTitle>
          * <span>Grade 2</span> cards appear based on probability.
        </SubTitle>
      </TitleContainer>

      <Table>
        <Tbody>
          <Tr>
            <Th>Card Type</Th>
            <Th>Card Name </Th>
            <Th>Probility</Th>
          </Tr>
          {data.map(({ type, parts, probabilities }) =>
            parts.map((part, index) => (
              <Tr key={`${type}-${part}`}>
                {index === 0 && <Td rowSpan={parts.length}>{type}</Td>}
                <Td>{part}</Td>
                <Td>{probabilities[index]}</Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </PageContainer>
  );
};

export default CardSum;

const PageContainer = styled.div`
  width: 80vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const Title = styled.div`
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #333;
  font-weight: 600;
  font-size: 2rem;
`;
const SubTitle = styled.div`
  width: 100%;
  text-align: start;
  padding: 20px 0px;
  font-size: 1.4rem;
  font-weight: 600;
  span:nth-of-type(1) {
    color: #55b333;
  }
  span:nth-of-type(2) {
    color: #8bc1d3;
  }
  ul {
    list-style: disc;
  }
  li {
    margin-left: 2rem;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 80px;
  width: 80%;
  font-weight: 600;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  font-size: 2rem;
  text-align: center;
`;

const Th = styled.th`
  border: 1px solid #000;
  padding: 10px 0;
  background-color: #222;
  color: #fff;
  font-size: 1.6rem;
`;

const Td = styled.td`
  padding: 5px 0px;
  height: 23px;
  font-size: 1.6rem;
  border: 1px solid #000;
  text-align: center;
  vertical-align: middle;
`;
const TitleContainer = styled.div`
  width: 80%;
`;
