import React from 'react';
import styled from "styled-components";
import {frameDecoM, nftBg, nftBgM, nftFrame, textDeco} from "../../assets/images";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import {theme} from "../../styles/theme";
import {useMediaQuery} from "react-responsive";


const Nft = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    });
    const NFT_DATA = [
        {
            id: 1,
            title: "Play",
            description: "Fight for victory",
        },
        {
            id: 2,
            title: "Collect",
            description: "Play games with \ncards",
        },
        {
            id: 3,
            title: "Trade",
            description: "Trade characters & \ncards",
        },
        {
            id: 4,
            title: "Ownership",
            description: isMobile?"Conversion of NFT from \nin-game items into assets":"Conversion of NFT from \nin-game items into \nassets",
        },
    ]
    return (
        <NftWrap id={"3"}>
            <img src={isMobile? nftBgM:nftBg} alt=""/>
            <ContentWrap>
                <Title>NFT</Title>
                <SubTitle>Why SUPERKOLA TACTICS?</SubTitle>
                <DescText>
                    <p className={"outlinedText"} data-text={"SUPERKOLA TACTICS is a blockchain game with a fruitful experience"}>
                        SUPERKOLA TACTICS is a blockchain game with a fruitful experience
                    </p>
                </DescText>
                <CardWrap>
                {NFT_DATA.map((item) => (<CardBox key={item.id}>
                    <p className={"outlinedText"} data-text={item.title}>{item.title}</p>
                    <img src={textDeco} className={"deco"} />
                    <span>{item.description}</span>
                </CardBox>))}
                </CardWrap>
            </ContentWrap>
        </NftWrap>
    );
};

export default Nft;

const NftWrap = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  img {
    display: block;
    width: 100%;
  }
  @media ${theme.mq.mobile}{
    max-height: unset;
  }
`;
const ContentWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  
  @media ${theme.mq.desktop} {
    padding-top: 6.25vw;
  }
  @media ${theme.mq.mobile}{
    padding-top: 20vw;
  }
`;
const DescText = styled.div`
  padding-top: 48px;
  margin-bottom: 24px;
    .outlinedText {
      color: ${theme.colors.accent};
      font-size: 2.8rem;
    }
  @media ${theme.mq.desktop} {
    padding-top: 2.5000vw;
    margin-bottom: 1.2500vw;
    .outlinedText {
      font-size: 1.4583vw;
    }
  }
  @media ${theme.mq.mobile}{
    padding-top: 0;
    margin-bottom: 9.1667vw;
    width: 84.7222vw;
    .outlinedText {
      font-size: 4.4444vw;
      line-height: 1.62;
    }
  }
`;
const CardWrap = styled.div`
  display: flex;
  gap: 24px;
  @media ${theme.mq.desktop} {
    gap: 1.2500vw;
  }
  @media ${theme.mq.mobile}{
    flex-direction: column;
    gap: 11.6667vw
  }
`;
const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 111px;
  gap: 24px;
  width: 332px;
  height: 354px;
  background: url(${nftFrame}) no-repeat center center;
  background-size: cover;
  .outlinedText {
    font-size: 3.2rem;
    line-height: 0.9;
  }
  img {
    transform: rotate(90deg);
    width: 24px;
  }
  span {
    font-size: 2.4rem;
    line-height: 1.33;
    color: #000;
    white-space: pre-line;
  }
  @media ${theme.mq.desktop} {
    padding-top: 5.7813vw;
    gap: 1.2500vw;
    width: 17.2917vw;
    height: 18.4375vw;

    .outlinedText {
      font-size: 1.6667vw;
    }
    img {
      width: 1.2500vw;
    }
    span {
      font-size: 1.25vw;
    }
  }
  @media ${theme.mq.mobile}{
    position: relative;
    padding: 6.6667vw 0;
    gap: 2.2222vw;
    background: #FFFDF98C;
    width: 88.8889vw;
    height: auto;
    border-top: 0.5556vw solid #006B75;
    border-bottom: 0.5556vw solid #006B75;
    &:before,&:after {
      position: absolute;
      content:"";
      display: block;
      width: 13.3333vw;
      height: 5vw;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url(${frameDecoM});
    }
    &:before {
      top: -3.0556vw;
    }
    &:after {
      transform: scale(-1);
      bottom: -3.0556vw;
    }
    
    .outlinedText {
      font-size: 5vw;
      line-height: 1.66;
    }
    img {
      width: 3.3333vw;
    }
    span {
      font-size: 4.4444vw;
      line-height: 1.62;
      width: 57.2222vw;
      white-space: normal;
    }
    &:last-child {
      span {
        white-space: pre;
      }
    }
  }
`;