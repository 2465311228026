const fontSizes = {
    title: "3.6rem",
    subTitle: "4.8rem",
    nav: "1.8rem",
    box: "3.2rem",
    footer: "1.4rem",
};

const colors = {
    preRegistrationBg: "linear-gradient(180deg, #A5E6FC 0%, #72C9D0 100%)",
    gameMetaBg: "linear-gradient(180deg, #B1E4E9 0%, #66C7D1 100%)",
    mainBg: "#83CFD7",
    headerBg: "#00282C",
    accent: "#FEEB8D",
    btn: "#006B75",
    text: "#fff",
    darkText: "#173C46",
};
const positions = {
    flexCenterXY: "display: flex; justify-content: center; align-items: center;",
    flexCenterX: "display: flex; justify-content: center;",
    flexCenterY: "display: flex; align-items: center;",
    flexColumnY: "display: flex; flex-direction: column; align-items: center;",
    spaceBetween: "display: flex; justify-content: space-between;",
    spaceAround: "display: flex; justify-content: space-around;",
};

const mq = {
    mobile: "(max-width: 600px)",
    tablet: "(max-width: 1024px)",
    desktop: "(max-width: 1920px)",
    header: "(max-width: 1300px)",
};

export const theme = {
    fontSizes,
    colors,
    positions,
    mq,
};
