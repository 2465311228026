import styled from 'styled-components';

const data = [
  {
    type: 'Eye',
    parts: [
      'Eye Parts - 1',
      'Eye Parts - 2',
      'Eye Parts - 3',
      'Eye Parts - 4',
      'Eye Parts - 5',
      'Eye Parts - 6',
      'Eye Parts - 7',
      'Eye Parts - 8',
      'Eye Parts - 9',
      'Eye Parts - 10',
      'Eye Parts - 11',
    ],
    probabilities: [
      '12.50%',
      '12.50%',
      '12.50%',
      '12.50%',
      '8.75%',
      '8.75%',
      '8.75%',
      '8.75%',
      '5.00%',
      '5.00%',
      '5.00%',
    ],
  },
  {
    type: 'Mouth',
    parts: [
      'Mouth Parts - 1',
      'Mouth Parts - 2',
      'Mouth Parts - 3',
      'Mouth Parts - 4',
      'Mouth Parts - 5',
      'Mouth Parts - 6',
      'Mouth Parts - 7',
      'Mouth Parts - 8',
      'Mouth Parts - 9',
      'Mouth Parts - 10',
      'Mouth Parts - 11',
      'Mouth Parts - 12',
    ],
    probabilities: [
      '10.00%',
      '10.00%',
      '10.00%',
      '10.00%',
      '10.00%',
      '10.00%',
      '7.50%',
      '7.50%',
      '7.50%',
      '7.50%',
      '5.00%',
      '5.00%',
    ],
  },
  {
    type: 'Tattoo',
    parts: [
      'Tattoo Parts - 1',
      'Tattoo Parts - 2',
      'Tattoo Parts - 3',
      'Tattoo Parts - 4',
      'Tattoo Parts - 5',
      'Tattoo Parts - 6',
      'Tattoo Parts - 7',
      'Tattoo Parts - 8',
      'Tattoo Parts - 9',
      'Tattoo Parts - 10',
      'Tattoo Parts - 11',
    ],
    probabilities: [
      '12.50%',
      '12.50%',
      '12.50%',
      '12.50%',
      '12.50%',
      '12.50%',
      '5.00%',
      '5.00%',
      '5.00%',
      '5.00%',
      '5.00%',
    ],
  },
  {
    type: 'Hair',
    parts: [
      'Hair Parts - 1',
      'Hair Parts - 2',
      'Hair Parts - 3',
      'Hair Parts - 4',
      'Hair Parts - 5',
      'Hair Parts - 6',
      'Hair Parts - 7',
      'Hair Parts - 8',
      'Hair Parts - 9',
      'Hair Parts - 10',
      'Hair Parts - 11',
      'Hair Parts - 12',
      'Hair Parts - 13',
      'Hair Parts - 14',
      'Hair Parts - 15',
    ],
    probabilities: [
      '8.00%',
      '8.00%',
      '8.00%',
      '8.00%',
      '7.00%',
      '7.00%',
      '7.00%',
      '7.00%',
      '7.00%',
      '7.00%',
      '5.00%',
      '5.00%',
      '5.00%',
      '5.00%',
      '5.00%',
    ],
  },
  {
    type: 'Costume',
    parts: [
      'Costume Parts - 1',
      'Costume Parts - 2',
      'Costume Parts - 3',
      'Costume Parts - 4',
      'Costume Parts - 5',
      'Costume Parts - 6',
      'Costume Parts - 7',
      'Costume Parts - 8',
      'Costume Parts - 9',
      'Costume Parts - 10',
      'Costume Parts - 11',
      'Costume Parts - 12',
      'Costume Parts - 13',
      'Costume Parts - 14',
      'Costume Parts - 15',
      'Costume Parts - 16',
      'Costume Parts - 17',
      'Costume Parts - 18',
      'Costume Parts - 19',
    ],
    probabilities: [
      '8.00%',
      '8.00%',
      '8.00%',
      '8.00%',
      '8.00%',
      '8.00%',
      '8.00%',
      '6.00%',
      '6.00%',
      '6.00%',
      '3.20%',
      '3.20%',
      '3.20%',
      '3.20%',
      '3.20%',
      '2.50%',
      '2.50%',
      '2.50%',
      '2.50%',
    ],
  },
  {
    type: 'Weapon',
    parts: [
      'Accessory Parts - 1',
      'Accessory Parts - 2',
      'Accessory Parts - 3',
      'Weapon Parts - 1',
      'Weapon Parts - 2',
      'Weapon Parts - 3',
      'Weapon Parts - 4',
      'Weapon Parts - 5',
      'Weapon Parts - 6',
    ],
    probabilities: [
      '16.75%',
      '16.75%',
      '16.75%',
      '25.00%',
      '25.00%',
      '15.00%',
      '15.00%',
      '10.00%',
      '10.00%',
    ],
  },
];

const CharacterSumCard = () => {
  return (
    <div>
      <PageContainer>
        <TitleContainer>
          <Title>Character summoning probability information </Title>
          <SubTitle>
            <div>*A character appears by combining 7 parts. </div>
            <br />
            <div>*Only grade 3 parts appear. </div>
            <br />
            <div>
              *The probability below is the probability table for the items
              below.
              <li>
                Warrior Rare CharacterPack, MagicianRare CharacterPack, Hunter
                Rare CharacterPack, Random Rare CharacterPack{' '}
              </li>
            </div>
          </SubTitle>
        </TitleContainer>
        <Table>
          <Tbody>
            <Tr>
              <Th>Parts Type</Th>
              <Th>Grade</Th>
              <Th>Number of parts by grade</Th>
              <Th>Part Individual Probability</Th>
            </Tr>
            {data.map(({ type, parts, probabilities }) =>
              parts.map((part, index) => (
                <Tr key={`${type}-${part}`}>
                  {index === 0 && <Td rowSpan={parts.length}>{type}</Td>}
                  <Td>Rare</Td>
                  <Td>{part}</Td>
                  <Td>{probabilities[index]}</Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </PageContainer>
    </div>
  );
};

export default CharacterSumCard;

const PageContainer = styled.div`
  width: 80vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const Title = styled.div`
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #333;
  font-weight: 600;
  font-size: 2rem;
`;
const SubTitle = styled.div`
  width: 100%;
  text-align: start;
  padding: 20px 0px;
  font-size: 1.4rem;
  font-weight: 600;
  span:nth-of-type(1) {
    color: #55b333;
  }
  span:nth-of-type(2) {
    color: #8bc1d3;
  }
  ul {
    list-style: disc;
  }
  li {
    margin-left: 2rem;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 80px;
  width: 80%;
  font-weight: 600;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  font-size: 2rem;
  text-align: center;
`;

const Th = styled.th`
  border: 1px solid #000;
  padding: 10px 0;
  background-color: #222;
  color: #fff;
  font-size: 1.6rem;
`;

const Td = styled.td`
  padding: 5px 0px;
  height: 23px;
  font-size: 1.6rem;
  border: 1px solid #000;
  text-align: center;
  vertical-align: middle;
`;
const TitleContainer = styled.div`
  width: 80%;
`;
