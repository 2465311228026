import {
  logoBingX,
  logoBitget,
  logoBitmart,
  logoGate,
  logoGopax,
  logoMexc,
  logoProbit,
  logoXt,
  tokenImg1,
  tokenImg2,
  tokenImg3,
} from '../../assets/images';

export const TOKEN_DATA = [
  {
    id: 1,
    title: 'Curio',
    imgSrc: `${tokenImg1}`,
    description: 'In-game assets means for getting in-game reward',
  },
  {
    id: 2,
    title: '(p)ERDL',
    imgSrc: `${tokenImg2}`,
    description: 'Utility token means for exchange',
  },
  {
    id: 3,
    title: 'CVTX',
    imgSrc: `${tokenImg3}`,
    description: 'Native token \nfor ecosystem',
  },
];

export const BTN_DATA = [
  {
    id: 1,
    imgSrc: logoGate,
    title: 'Gate.io',
    link: 'https://www.gate.io/trade/CVTX_USDT',
  },
  {
    id: 2,
    imgSrc: logoBitget,
    title: 'Bitget',
    link: 'https://www.bitget.com/en-GB/spot/CVTXUSDT',
  },
  {
    id: 3,
    imgSrc: logoMexc,
    title: 'MEXC Global',
    link: 'https://www.mexc.com/ko-KR/exchange/CVTX_USDT?_from=market',
  },
  {
    id: 4,
    imgSrc: logoProbit,
    title: 'Probit Golobal',
    link: 'https://www.probit.com/app/exchange/CVTX-USDT',
  },
  {
    id: 5,
    imgSrc: logoBitmart,
    title: 'Bitmart',
    link: 'https://www.bitmart.com/trade/en-US?symbol=CVTX_USDT',
  },
  {
    id: 6,
    imgSrc: logoGopax,
    title: 'GOPAX',
    link: 'https://www.gopax.co.kr/exchange/cvtx-krw',
  },
  {
    id: 7,
    imgSrc: logoBingX,
    title: 'BingX',
    link: 'https://bingx.com/en-us/spot/CVTXUSDT',
  },
  {
    id: 8,
    imgSrc: logoXt,
    title: 'xt.com',
    link: 'https://www.xt.com/en/trade/cvtx_usdt',
  },
];
