import React from "react";
import styled from "styled-components";

import { theme } from "../../styles/theme";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import GameSlide from "../../components/Slide";
import CardBox from "./CardBox";
import { gameMetaBg, gameMetaBgM } from "../../assets/images";
import { useMediaQuery } from "react-responsive";

const GameMeta = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });
  return (
    <GameMetaWrap id={"2"}>
      <img src={isMobile ? gameMetaBgM : gameMetaBg} alt="" />
      <ContentWrap>
        <Title>Game Meta</Title>
        <ClassWrap>
          <SubTitle>Class</SubTitle>
          <GameSlide />
        </ClassWrap>
        <CardWrap>
          <SubTitle>Card</SubTitle>
          <CardBoxWrap>
            <CardBox>Public Card</CardBox>
            <CardBox>Class Card</CardBox>
          </CardBoxWrap>
        </CardWrap>
      </ContentWrap>
    </GameMetaWrap>
  );
};

export default GameMeta;

const GameMetaWrap = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  max-height: 2350px;
  img {
    display: block;
    width: 100%;
  }
  @media (min-width: 1925px) {
    border-bottom: 4px solid #fde6b5;
  }
  @media ${theme.mq.mobile} {
    max-height: unset;
  }
`;
const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 120px;
  width: 100%;
  height: 100%;
  @media ${theme.mq.desktop} {
    padding-top: 6.25vw;
  }
  @media ${theme.mq.mobile} {
    padding-top: 20vw;
  }
`;

const ClassWrap = styled.div`
  margin-bottom: 160px;
  @media ${theme.mq.desktop} {
    margin-bottom: 8.3333vw;
  }
  @media ${theme.mq.mobile} {
    margin-bottom: 20.8333vw;
  }
`;
const CardWrap = styled.div``;
const CardBoxWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  padding-top: 52px;
  @media ${theme.mq.desktop} {
    gap: 1.25vw;
    padding-top: 2.7083vw;
  }

  @media ${theme.mq.mobile} {
    padding-top: 0;
    flex-direction: column;
    gap: 11.1111vw;
  }
`;
