import styled from 'styled-components';

const data = [
  {
    level: 'Level 0',
    basicProbability: '85.00%',
    increaseProbability: '1.00% (Up to 15) Max 100%',
  },
  {
    level: 'Level 1',
    basicProbability: '83.00%',
    increaseProbability: '0.81% (Up to 21) Max 100%',
  },
  {
    level: 'Level 2',
    basicProbability: '81.00%',
    increaseProbability: '0.65% (Up to 29) Max 100%',
  },
  {
    level: 'Level 3',
    basicProbability: '79.00%',
    increaseProbability: '0.40% (Up to 40) Max 95%',
  },
  {
    level: 'Level 4',
    basicProbability: '77.00%',
    increaseProbability: '0.23% (Up to 56) Max 80%',
  },
];
const CardEnchant = () => {
  return (
    <>
      <PageContainer>
        <TitleContainer>
          <Title>Card Enchant Probability</Title>
          <SubTitle>
            <div>
              * Adding a <span>Reconstitution Powder</span> increases the
              probability.
            </div>
            <div>
              * The default probability and the probability of increasing per
              Reconstitution Powder are different for each card level.
            </div>
          </SubTitle>
        </TitleContainer>

        <Table>
          <thead>
            <Tr>
              <Th>Card Level</Th>
              <Th>Basic Probability</Th>
              <Th>Probability of Increasing per Count</Th>
            </Tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <Tr key={index}>
                <Td>{row.level}</Td>
                <Td>{row.basicProbability}</Td>
                <Td>{row.increaseProbability}</Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </PageContainer>
    </>
  );
};

export default CardEnchant;
const PageContainer = styled.div`
  width: 80vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const Title = styled.div`
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #333;
  font-weight: 600;
  font-size: 2rem;
`;
const SubTitle = styled.div`
  width: 100%;
  text-align: start;
  padding: 20px 0px;
  font-size: 1.4rem;
  font-weight: 600;
  span:nth-of-type(1) {
    color: #55b333;
  }
  span:nth-of-type(2) {
    color: #8bc1d3;
  }
  ul {
    list-style: disc;
  }
  li {
    margin-left: 2rem;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 80px;
  width: 80%;
  font-weight: 600;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  font-size: 2rem;
  text-align: center;
`;

const Th = styled.th`
  border: 1px solid #000;
  padding: 10px 0;
  background-color: #222;
  color: #fff;
  font-size: 1.6rem;
`;

const Td = styled.td`
  padding: 5px 0px;
  height: 23px;
  font-size: 1.6rem;
  border: 1px solid #000;
  text-align: center;
  vertical-align: middle;
`;
const TitleContainer = styled.div`
  width: 80%;
`;
