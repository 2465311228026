import React from "react";
import styled from "styled-components";
import {
  cardCharacter,
  cardGold,
  cardRuby,
  cardStamina,
  cardTicket,
  preRegistrationBg,
  preRegistrationBgM,
} from "../../assets/images";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import RewardCard from "./RewardCard";
import { theme } from "../../styles/theme";
import { useMediaQuery } from "react-responsive";
const REWARD_DATA = [
  {
    id: 1,
    price: "10,000",
    imgSrc: `${cardGold}`,
    text: "Gold",
  },
  {
    id: 2,
    price: "100",
    imgSrc: `${cardRuby}`,
    text: "Ruby",
  },
  // {
  //     id: 3,
  //     price: "200",
  //     imgSrc: `${cardStamina}`,
  //     text: "Stamina"
  // },
  {
    id: 4,
    price: "3",
    imgSrc: `${cardTicket}`,
    text: "Summon \nCard Ticket",
  },
  {
    id: 5,
    price: "1",
    imgSrc: `${cardCharacter}`,
    text: "Summon \nCharacter Ticket",
  },
];
const PreRegistration = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });
  return (
    <PreRegistrationWrap id={"1"}>
      <img
        src={isMobile ? preRegistrationBgM : preRegistrationBg}
        alt=""
        style={isMobile ? { opacity: 0.4 } : {}}
      />
      <ContentWrap>
        <Title>Pre-Registration</Title>
        <SubTitle>Pre-Registration Rewards!</SubTitle>
        <CardWrap>
          {REWARD_DATA.map((data) => (
            <RewardCard key={data.id} value={data} />
          ))}
        </CardWrap>
        <TextWrap>
          <h2
            className={"outlinedText"}
            data-text={"Note on Event Participation"}
          >
            Note on Event Participation
          </h2>
          <p>{`Rewards will be sent after the official launch. \nCheck out the community for more details!`}</p>
        </TextWrap>
      </ContentWrap>
    </PreRegistrationWrap>
  );
};

export default PreRegistration;

const PreRegistrationWrap = styled.div`
  position: relative;
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
  @media ${theme.mq.mobile} {
    max-height: unset;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 120px;
  width: 100%;

  @media ${theme.mq.desktop} {
    padding-top: 6.25vw;
  }
  @media ${theme.mq.mobile} {
    padding-top: 20vw;
  }
`;
const CardWrap = styled.ul`
  display: flex;
  gap: 20px;
  padding: 48px 0 72px;
  @media ${theme.mq.desktop} {
    gap: 1.0417vw;
    padding: 2.5vw 0 3.75vw;
  }
  @media ${theme.mq.mobile} {
    flex-direction: column;
    /* gap: 4.4444vw; */
    gap: 16px;
    padding: 0 0 40px;
  }
`;
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  h2 {
    font-size: 2.8rem;
    color: ${theme.colors.accent};
  }
  p {
    font-size: 2.4rem;
    line-height: 1.33;
    text-align: center;
    color: ${theme.colors.headerBg};
  }
  @media ${theme.mq.desktop} {
    gap: 0.625vw;
    h2 {
      font-size: 1.4583vw;
    }
    p {
      font-size: 1.25vw;
    }
  }
  @media ${theme.mq.mobile} {
    gap: 3.3333vw;
    h2 {
      font-size: 4.4444vw;
    }
    .outlinedText {
      &:before {
        --stroke-width: 2px;
      }
    }
    p {
      white-space: pre-line;
      line-height: 1.42;
      font-size: 3.8889vw;
    }
  }
`;
