import React from "react";
import styled from "styled-components";
import Intro from "../Intro";
import PreRegistration from "../PreRegistration";
import GameMeta from "../GameMeta";
import Nft from "../Nft";
import Tokenomics from "../Tokenomics";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LinkNav from "../../components/LinkNav";

const Home = () => {
  return (
    <>
      <Header />
      <HomeWrapper>
        <Intro />
        <PreRegistration />
        <GameMeta />
        <Nft />
        <Tokenomics />
        <LinkNav />
      </HomeWrapper>
      <Footer />
    </>
  );
};

export default Home;

const HomeWrapper = styled.div`
  overflow: hidden;
`;
