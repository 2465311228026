import React, {ReactNode} from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";

interface SubTitleProps {
    children : ReactNode;
}
const SubTitle = ({children} :SubTitleProps) => {
    return (
        <StyledSelf className={"outlinedText"} data-text={children}>
            {children}
        </StyledSelf>
    );
};

export default SubTitle;

const StyledSelf = styled.div`
  display: inline-block;
  position: relative;
  font-size: ${theme.fontSizes.subTitle};
  line-height: 72px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  &.outlinedText {
    &::before {
      --stroke-width: 4px;
    }
  }
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 60%;
    left: -30%;
    height: 24px;
    width: 160%;
    opacity: 0.5;
    background: linear-gradient(270deg, rgba(254, 247, 184, 0.1) 0%, #FEF7B8 47.92%, rgba(254, 247, 184, 0.1) 100%);
    filter: blur(5px);
    z-index: -1;
  }
  
  @media ${theme.mq.desktop} {
    line-height: 3.7500vw;
    font-size: 2.5vw;
    &.outlinedText {
      &::before {
        --stroke-width: 0.2083vw;
      }
    }
    &::after {
      height: 1.2500vw;
    }
  }
  @media ${theme.mq.mobile}{
    font-size: 5.5556vw;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 8.3333vw;
    
    &.outlinedText {
      &::before {
        --stroke-width: 0.5556vw;
      }
    }
    &::after {
      top: 50%;
      height: 4.7222vw;
      opacity: 0.7;
      background: linear-gradient(270deg, rgba(254, 247, 184, 0.00) 0%, #FEF7B8 50%, rgba(254, 247, 184, 0.00) 100%);
      filter: blur(5px);
    }
  }
`;