import React from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";
import {useMediaQuery} from "react-responsive";
import {SLIDE_DATA} from "./slideData";
import {comingSoonDeco} from "../../assets/images";

interface SlideBoxProps {
    data : {
        id: number;
        job: string;
        title: string;
        description: string;
    }
    currentIndex: number;
}
const SlideContentBox = ({data, currentIndex} :SlideBoxProps) => {
    const {id, job, title, description} = data;
    const getImage = (imgName: string) => require(`../../assets/images/${imgName}`);
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    });
    return (
        <SlideContentWrap>
            <ContentWrap>
                <JobTitle>
                    <ImgWrap><img src={getImage(`badge_${job}.png`)}/></ImgWrap>
                    <p className={"outlinedText"} data-text={job}>{job}</p>
                </JobTitle>
                {isMobile&& <CharacterBox><img src={getImage(`slide_game_cha_${id}_m.png`)} alt=""/></CharacterBox>}
                <DescBox>
                    <p className={"outlinedText"} data-text={`“${title}”`}>“{title}”</p>
                    <span>{description}</span>
                </DescBox>
            </ContentWrap>
            <StatWrap>
                {Object.entries(SLIDE_DATA[currentIndex].stat).map(([key, value]) => (
                    <li key={key}>
                        <p>{key}</p>
                        <StatBox>
                            <StatPoint point={value} />
                        </StatBox>
                    </li>
                ))}
            </StatWrap>
            {isMobile && currentIndex === 3 &&
                <span className={"dimBg"}>
                        <img src={comingSoonDeco} alt=""/>
                        <p className={"outlinedText"} data-text={"Coming Soon"}>Coming Soon</p>
                        <img src={comingSoonDeco} alt=""/>
                    </span>
            }
        </SlideContentWrap>
    );
};

export default SlideContentBox;

const SlideContentWrap = styled.div`
  position: relative;
  display: block;
  text-align: left;
  margin-bottom: 37px;
  width: 100%;
  height: 100%;
  @media ${theme.mq.desktop}{
    margin-bottom: 1.9271vw;
  }
  @media ${theme.mq.mobile}{
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    margin-bottom: 6.6667vw;
    .dimBg {
      position: absolute;
      background: rgba(0, 42, 46, 0.85) !important;
      background-size: cover;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 101% !important;
      gap: 1.6667vw;
      padding-top: 65vw;
      align-items: flex-start;
      z-index: 99;
      img {
        width: 7.7778vw;
        margin-top: -0.5556vw;
      }
      .outlinedText {
        font-size: 6.6667vw !important;
        text-shadow: 0px 0px 4.4444vw rgba(254, 235, 141, 0.60);
        &:before {
          --stroke-width: 0.8333vw;
        }
      }
    }
  }
`;
const ContentWrap = styled.div`
    padding: 50px 0 0 80px;
  @media ${theme.mq.desktop}{
    padding: 2.6042vw 0 0 4.1667vw;
  }
  @media ${theme.mq.mobile}{
    padding: 0;
  }
`;
const JobTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 27px;
  .outlinedText {
    margin-top: -10px;
    font-size: 4.8rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: ${theme.colors.accent};
    &::before {
      --stroke-width: 4px;
    }
  }
  @media ${theme.mq.desktop}{
    gap: 1.6667vw;
    margin-bottom: 1.4063vw;
    .outlinedText {
      margin-top: -0.5208vw;
      font-size: 2.5vw;
      &::before {
        --stroke-width: 0.2083vw;
      }
    }
  }
  @media ${theme.mq.mobile}{
    padding: 7.5vw 0 0 6.6667vw;
    gap: 5.5556vw;
    margin-bottom: 0;
    .outlinedText {
      font-size: 6.6667vw;
      &::before {
        --stroke-width: 3px;
      }
    }
  }
`;
const ImgWrap = styled.div`
  width: 83px;
  img{
    width: 100%;
  }
  
  @media ${theme.mq.desktop}{
    width: 4.3229vw;
  }
  @media ${theme.mq.mobile}{
    width: 11.6667vw;
  }
`;

const DescBox = styled.div`
  white-space: pre-line;
  .outlinedText {
    margin-bottom: 20px;
    font-size: 3.6rem;
    line-height: 1.38;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.60);
  }
    span {
      display: block;
      width: 550px;
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 1.5;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.60);
    }
  
  @media ${theme.mq.desktop}{
    .outlinedText {
      margin-bottom: 1.0417vw;
      font-size: 1.875vw;
    }
    span {
      width: 28.6458vw;
      font-size: 1.25vw;
    }
  }
  @media ${theme.mq.mobile}{
    margin-top: -5vw;
    padding-left: 6.6667vw;
    .outlinedText{
      font-size: 5.5556vw;
      line-height: 1.5;
      margin-bottom: 3.3333vw;
    }
    span {
      width: 75.5556vw;
      font-size: 4.4444vw;
    }
  }
`;

const CharacterBox = styled.div`
  width: 88.8889vw;
  border: 0.5556vw solid transparent;
  img {
    width: 100%;
  }
`;
const StatWrap = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 494px;
  left: 0;
  gap: 16px;
  width: 531px;
  text-align: left;
  margin-left: 101px;
  li {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  p {
    font-size: 2.0rem;
    color: ${theme.colors.accent};
    text-align: right;
    width: 67px;
  }
  @media ${theme.mq.desktop}{
    gap: 0.8333vw;
    top: 25.7292vw;
    width: 27.6563vw;
    margin-left: 5.2604vw;
    li {
      gap: 1.0417vw;
    }
    p {
      font-size: 1.0417vw;
      width: 3.4896vw;
    }
  }
  @media ${theme.mq.mobile}{
    width: 75vw;
    gap: 3.3333vw;
    top: 175vw;
    margin-left: 6.6667vw;
    li {
      gap: 2.2222vw;
    }
    p {
      font-size: 3.8889vw;
      width: 13.6111vw;
    }
  }
`;
const StatBox = styled.div`
  position: relative;
  width: 444px;
  height: 20px;
  overflow: hidden;
  background: ${theme.colors.headerBg};
  border-radius: 100px;
  box-sizing: border-box;
  border: 4px solid ${theme.colors.headerBg};
  @media ${theme.mq.desktop}{
    width: 23.1250vw;
    height: 1.0417vw;
    border-width: 0.2083vw;
  }
  @media ${theme.mq.mobile}{
    width: 59.7222vw;
    height: 3.3333vw;
    border-width: 0.8333vw;
  }
`;

const StatPoint = styled.span< {point: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.point}%;
  height: 100%;
  border-radius: 100px;
  background: linear-gradient(90deg, #FAFE02 0%, #83FEFA 100%);
  transition: width .3s ease;
`;