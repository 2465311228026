import React from 'react';
import styled from 'styled-components';
import {
  PartsData,
  PartsHpData,
  PartsDefenseData,
  PartsCriticalData,
  PartsAtkData,
} from './data';

const PartsEnchant = () => {
  return (
    <div>
      <PageContainer>
        <TitleContainer>
          <Title>Parts Enchant</Title>
          <SubTitle>
            <div>
              * Fixed figures do not apply to parts reinforcement. It is applied
              randomly at a set number
            </div>
          </SubTitle>
        </TitleContainer>
        <Table>
          <thead>
            <Tr>
              <LevelTh rowSpan={2}>Parts</LevelTh>
              <LevelTh rowSpan={2}>Level</LevelTh>
              <Th colSpan={5}>Atk</Th>
            </Tr>
            <GradeTr>
              <Td>Common</Td>
              <Td>Uncommon</Td>
              <Td>Rare</Td>
              <Td>Unique</Td>
              <Td>Epic</Td>
            </GradeTr>
          </thead>
          <tbody>
            {PartsData.map((part) =>
              part.levels.map((data, index) => (
                <Tr key={`${part.part}-${data.level}`}>
                  {index === 0 && (
                    <Td rowSpan={part.levels.length}>{part.part}</Td>
                  )}
                  <Td>{data.level}</Td>
                  {data.atk.map((value, atkIndex) => (
                    <Td key={atkIndex}>{value}</Td>
                  ))}
                </Tr>
              ))
            )}
          </tbody>
        </Table>
        <Table>
          <thead>
            <Tr>
              <LevelTh rowSpan={2}>Parts</LevelTh>
              <LevelTh rowSpan={2}>Level</LevelTh>
              <Th colSpan={5}>Hp</Th>
            </Tr>
            <GradeTr>
              <Td>Common</Td>
              <Td>Uncommon</Td>
              <Td>Rare</Td>
              <Td>Unique</Td>
              <Td>Epic</Td>
            </GradeTr>
          </thead>
          <tbody>
            {PartsHpData.map((part) =>
              part.levels.map((data, index) => (
                <Tr key={`${part.part}-${data.level}`}>
                  {index === 0 && (
                    <Td rowSpan={part.levels.length}>{part.part}</Td>
                  )}
                  <Td>{data.level}</Td>
                  {data.atk.map((value, atkIndex) => (
                    <Td key={atkIndex}>{value}</Td>
                  ))}
                </Tr>
              ))
            )}
          </tbody>
        </Table>
        <Table>
          <thead>
            <Tr>
              <LevelTh rowSpan={2}>Parts</LevelTh>
              <LevelTh rowSpan={2}>Level</LevelTh>
              <Th colSpan={5}>Defense</Th>
            </Tr>
            <GradeTr>
              <Td>Common</Td>
              <Td>Uncommon</Td>
              <Td>Rare</Td>
              <Td>Unique</Td>
              <Td>Epic</Td>
            </GradeTr>
          </thead>
          <tbody>
            {PartsDefenseData.map((part) =>
              part.levels.map((data, index) => (
                <Tr key={`${part.part}-${data.level}`}>
                  {index === 0 && (
                    <Td rowSpan={part.levels.length}>{part.part}</Td>
                  )}
                  <Td>{data.level}</Td>
                  {data.atk.map((value, atkIndex) => (
                    <Td key={atkIndex}>{value}</Td>
                  ))}
                </Tr>
              ))
            )}
          </tbody>
        </Table>
        <Table>
          <thead>
            <Tr>
              <LevelTh rowSpan={2}>Parts</LevelTh>
              <LevelTh rowSpan={2}>Level</LevelTh>
              <Th colSpan={5}>Critical Rate</Th>
            </Tr>
            <GradeTr>
              <Td>Common</Td>
              <Td>Uncommon</Td>
              <Td>Rare</Td>
              <Td>Unique</Td>
              <Td>Epic</Td>
            </GradeTr>
          </thead>
          <tbody>
            {PartsCriticalData.map((part) =>
              part.levels.map((data, index) => (
                <Tr key={`${part.part}-${data.level}`}>
                  {index === 0 && (
                    <Td rowSpan={part.levels.length}>{part.part}</Td>
                  )}
                  <Td>{data.level}</Td>
                  {data.atk.map((value, atkIndex) => (
                    <Td key={atkIndex}>{value}</Td>
                  ))}
                </Tr>
              ))
            )}
          </tbody>
        </Table>
        <Table>
          <thead>
            <Tr>
              <LevelTh rowSpan={2}>Parts</LevelTh>
              <LevelTh rowSpan={2}>Level</LevelTh>
              <Th colSpan={5}>Atk Speed</Th>
            </Tr>
            <GradeTr>
              <Td>Common</Td>
              <Td>Uncommon</Td>
              <Td>Rare</Td>
              <Td>Unique</Td>
              <Td>Epic</Td>
            </GradeTr>
          </thead>
          <tbody>
            {PartsAtkData.map((part) =>
              part.levels.map((data, index) => (
                <Tr key={`${part.part}-${data.level}`}>
                  {index === 0 && (
                    <Td rowSpan={part.levels.length}>{part.part}</Td>
                  )}
                  <Td>{data.level}</Td>
                  {data.atk.map((value, atkIndex) => (
                    <Td key={atkIndex}>{value}</Td>
                  ))}
                </Tr>
              ))
            )}
          </tbody>
        </Table>
      </PageContainer>
    </div>
  );
};

export default PartsEnchant;

const PageContainer = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Title = styled.div`
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #333;
  font-weight: 600;
  font-size: 2rem;
`;

const SubTitle = styled.div`
  width: 100%;
  text-align: start;
  padding: 20px 0px;
  font-size: 1.4rem;
  font-weight: 600;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 80px;
  width: 80%;
  font-weight: 600;
`;

const Th = styled.th`
  border: 1px solid #000;
  padding: 5px 0px;
  background-color: #222;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
`;

const LevelTh = styled.th`
  width: calc(100% / 7);
  border: 1px solid #000;
  padding: 5px 0px;
  background-color: #222;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  vertical-align: middle;
`;

const Td = styled.td`
  padding: 5px 0px;
  font-size: 1.6rem;
  border: 1px solid #000;
  text-align: center;
  vertical-align: middle;
`;

const GradeTr = styled.tr`
  background-color: lightgray;
  width: calc(100% / 7);
  color: #fff;

  & > td:nth-child(2) {
    background-color: #55b333;
    width: calc(100% / 7);
  }
  & > td:nth-child(3) {
    background-color: #4a9cdb;
    width: calc(100% / 7);
  }
  & > td:nth-child(4) {
    background-color: #ebb820;
    width: calc(100% / 7);
  }
  & > td:nth-child(5) {
    background-color: #ff5656;
    width: calc(100% / 7);
  }
`;

const Tr = styled.tr`
  font-size: 2rem;
  text-align: center;
`;

const TitleContainer = styled.div`
  width: 80%;
`;
