import React from 'react';
import styled from 'styled-components';
import { skillData } from './data';

const Passive = () => {
  return (
    <>
      <PageContainer>
        <TitleContainer>
          <Title>Parts Passive Skill</Title>
          <SubTitle>
            <div>
              * The probability depends on the number of skills you have.
            </div>
            <RedText>
              <div>
                * Skills currently in possession are excluded from the list.
              </div>
              <div>
                ex) If you have the Fury Passive skill, you will be excluded
                from other slots.
              </div>
            </RedText>
          </SubTitle>
        </TitleContainer>
        <Table>
          <Tbody>
            <Tr>
              <Th>Number of skills held</Th>
              <Th>Number of skills that can come out</Th>
              <Th>Skill Individual Probabilities</Th>
            </Tr>
            <Tr>
              <Td>0</Td>
              <Td>13</Td>
              <Td>7.69%</Td>
            </Tr>
            <Tr>
              <Td>1</Td>
              <Td>12</Td>
              <Td>8.33%</Td>
            </Tr>
            <Tr>
              <Td>2</Td>
              <Td>11</Td>
              <Td>9.09%</Td>
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>10</Td>
              <Td>10.00%</Td>
            </Tr>
          </Tbody>
        </Table>
      </PageContainer>
      <PageContainer>
        <TitleContainer>
          <SubTitle>
            * Skill individual probabilities are rounded to the fourth decimal
            place and guided to the third, so the sum of probabilities may not
            be 100%.
          </SubTitle>
        </TitleContainer>

        <Table>
          <Tbody>
            <Tr>
              <LevelTh rowSpan={3}>Skill Name</LevelTh>
              <Th colSpan={4}>Probability</Th>
            </Tr>
            <Tr>
              <Th rowSpan={2}>0 Passive</Th>
              <Th rowSpan={2}>1 Passive</Th>
              <Th rowSpan={2}>2 Passive</Th>
              <Th rowSpan={2}>3 Passive</Th>
            </Tr>
            <Tr></Tr>
            {skillData.map((skill, index) => (
              <Tr key={index}>
                <Td>{skill.name}</Td>
                {skill.probabilities.map((probability, idx) => (
                  <Td key={idx}>{probability.toFixed(3)}%</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </PageContainer>
    </>
  );
};

export default Passive;
const PageContainer = styled.div`
  width: 80vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const Title = styled.div`
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #333;
  font-weight: 600;
  font-size: 2rem;
`;
const SubTitle = styled.div`
  width: 100%;
  text-align: start;
  padding: 20px 0px;
  font-size: 1.4rem;
  font-weight: 600;
  span:nth-of-type(1) {
    color: #55b333;
  }
  span:nth-of-type(2) {
    color: #8bc1d3;
  }
  ul {
    list-style: disc;
  }
  li {
    margin-left: 2rem;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 80px;
  width: 80%;
  font-weight: 600;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  font-size: 2rem;
  text-align: center;
`;

const Th = styled.th`
  border: 1px solid #000;
  padding: 10px 0;
  background-color: #222;
  color: #fff;
  font-size: 1.6rem;
`;

const Td = styled.td`
  padding: 5px 0px;
  height: 23px;
  font-size: 1.6rem;
  border: 1px solid #000;
  text-align: center;
  vertical-align: middle;
`;
const TitleContainer = styled.div`
  width: 80%;
`;
const RedText = styled.div`
  color: #ff5656;
  :nth-child(2) {
    margin-left: 20px;
  }
`;

const LevelTh = styled.th`
  width: calc(100% / 5);
  border: 1px solid #000;
  padding: 5px 0px;
  background-color: #222;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  vertical-align: middle;
`;
