import React, { useRef } from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { iconDiscord, iconFacebook, iconYoutube } from "../../assets/images";

const LinkNav = () => {
  return (
    <LinkBar>
      <li>
        <a
          href="https://discord.com/invite/carrieverseofficial"
          target="_blank"
        >
          <img src={iconDiscord} alt="discord icon" />
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/@CarrieVerseOfficial" target="_blank">
          <img src={iconYoutube} alt="youtube icon" />
        </a>
      </li>

      <li>
        <a href="https://twitter.com/CarrieVerse" target="_blank">
          <img src={iconFacebook} alt="facebook icon" />
        </a>
      </li>
    </LinkBar>
  );
};

export default LinkNav;

const LinkBar = styled.ul`
  position: fixed;
  bottom: 40px;
  right: 80px;
  display: flex;
  z-index: 9999;
  transition: all 1s ease;
  gap: 12px;
  li {
    width: 40px;
    height: 40px;
    a {
      display: block;
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;
      border-radius: 100%;
      background: #fff;
      img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  @media ${theme.mq.mobile} {
    display: flex;
    justify-content: center;
    padding-bottom: 36px;
    position: static;
    gap: 4.4444vw;
    li {
      width: 10vw;
      height: 10vw;
    }
  }
`;
