import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";
import {logo} from "../../assets/images";
import {pageNumberStore} from "../../store/store";
import { Link } from "react-scroll";
import {useMediaQuery} from "react-responsive";
import HamburgerIcon from "./HamburgerIcon";
import LinkNav from "../LinkNav";

interface navProps{
    navstate : boolean;
}
export const PAGE_MENU = [
    { name: 'Pre-Registration', key: 1, pageNum: [1] },
    { name: 'Game Meta', key: 2 , pageNum: [2]},
    { name: 'NFT', key: 3, pageNum: [3] },
    { name: 'Tokenomics', key: 4, pageNum: [4] },
];
const Header = () => {
    const {pageNumber, setPageNumber} = pageNumberStore();
    const handlePageChange = (menu:number) => {
        setPageNumber(menu);
    };
    const [navState, setNavState] = useState(false);
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    });
   const handleMobilePageChange = (menu:number) => {
       setNavState(false);
       setPageNumber(menu);
   }

    return (
        <HeaderWrap>
            <InnerWidth>
                <NavLogo>
                    <Link to="0" onClick={()=> handlePageChange(0)} smooth={true}>
                        <img src={logo} alt="superkola logo"/>
                    </Link>
                </NavLogo>
                {isMobile?
                    <>
                        <NavMobile navstate={navState}>
                            <NavListWrap className={"menuList"}>
                                {PAGE_MENU.map((menu,i) => (
                                    <NavList key={i}>
                                        <Link
                                            to={`${menu.key}`}
                                            title={menu.name.toUpperCase()}
                                            onClick={() => handleMobilePageChange(menu.key)}
                                            smooth={true}
                                            spy={true}
                                            activeClass={'active'}
                                            className={"outlinedText"}
                                            data-text={menu.name}
                                        >
                                            {menu.name}
                                        </Link>
                                    </NavList>
                                ))}
                            </NavListWrap>
                            <LinkNav/>
                        </NavMobile>
                        <HamburgerIcon navState={navState} setNavState={setNavState}/>
                    </>
                    :
                    <NavListWrap>
                    {PAGE_MENU.map((menu,i) => (
                        <NavList key={i}>
                            <Link
                                to={`${menu.key}`}
                                title={menu.name.toUpperCase()}
                                onClick={() => handlePageChange(menu.key)}
                                smooth={true}
                                spy={true}
                                activeClass={'active'}
                                className={"outlinedText"}
                                data-text={menu.name}
                            >
                                {menu.name}
                            </Link>
                        </NavList>
                    ))}
                </NavListWrap>
                }

            </InnerWidth>
        </HeaderWrap>
    );
};

export default Header;

const HeaderWrap = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  background-color: ${theme.colors.headerBg};
  z-index: 99999;
  @media ${theme.mq.desktop}{
    height: 3.75vw;
    min-height: 54px;
  }
  @media ${theme.mq.mobile}{
    height: 15.5556vw;
  }
`;
const InnerWidth = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 1400px;
  height: 100%;
  @media ${theme.mq.desktop}{
    width: 72.9167vw;
  }
  @media ${theme.mq.mobile}{
    width: 88.8889vw;
  }
`;
const NavLogo = styled.h2`
  width: 168px;
  height: 100%;
  cursor: pointer;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
  }
  @media ${theme.mq.desktop}{
    width: 8.75vw;
    min-width: 100px;
  }
  @media ${theme.mq.mobile}{
    width: 27.7778vw;
  }
`;

const NavListWrap = styled.ul`
  display: flex;
  gap: 48px;
  @media ${theme.mq.desktop}{
    gap: 2.5vw;
  }
`;

const NavList = styled.li`
  font-size: ${theme.fontSizes.nav};
  cursor: pointer;
  transition: all 0.3s ease;
  a {
    display: block;
    font-size: 1.8rem;
    transition: all .3s ease;
    font-weight: 600;
    &:hover {
      color: ${theme.colors.accent};
    }
  }
  .active {
    color: ${theme.colors.accent};
    text-shadow: 0px 0px 12px rgba(254, 235, 141, 0.8);
  }
  @media ${theme.mq.desktop}{
    a {
      font-size: 1.4rem;
    }
    
  }
`;

//mobile navigation
const NavMobile = styled.div<navProps>`
  position: fixed;
  top:0;
  right:${({navstate}) => navstate === true? "0" : "-100%" };
  bottom:0;
  padding-bottom: 11.1111vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 65.5vw;
  background: ${theme.colors.headerBg};
  box-shadow: -4px 0px 8px 2px rgba(0, 0, 0, 0.30);
  transition: all ease 0.2s;
  
  ul.menuList {
    padding-top: 32.2222vw;
    display: block;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 6.6667vw;
      height: 8.8889vw;
      a {
        font-size: 4.4444vw;
      }
    }
  }

`;