import React from 'react';
import styled from "styled-components";
import {btnArrowActive, btnArrowOff, btnArrowOn} from "../../assets/images";
import {SLIDE_DATA} from "./slideData";
import {theme} from "../../styles/theme";

interface NextArrowProps {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    currentIndex?: number;
}
export default function NextArrow({onClick ,currentIndex} :NextArrowProps){
    const isLastSlide = currentIndex === SLIDE_DATA.length-1;
    return (
        <NextBtn className={`next-arrow ${isLastSlide ? 'disabled' : ''}`} onClick={onClick}></NextBtn>
    );
};

const NextBtn = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + 41px);
  transform: translateY(-50%) scaleX(-1);
  width: 96px;
  height: 96px;
  background-image: url(${btnArrowActive});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all ease 0.2s;
  z-index: 9999;
  &:hover {
    background-image: url(${btnArrowOn});
    cursor: pointer;
  }
  &.disabled {
    background-image: url(${btnArrowOff});
    &:hover {
      background-image: url(${btnArrowOff});
      cursor: unset;
    }
  }
  @media ${theme.mq.desktop}{
    left: calc(100% + 2.1354vw);
    width: 5vw;
    height: 5vw;
  }
  @media ${theme.mq.mobile}{
    top: 83.0556vw;
    left: calc(100% - 6.1111vw);
    width: 9.4444vw;
    height: 9.4444vw;
    &:hover {
      background-image: url(${btnArrowActive});
      cursor: pointer;
    }
    &.disabled {
      background-image: none;
      &:hover {
        background-image: none;
        cursor: unset;
      }
    }
  }
`