import React, { ReactNode } from "react";
import styled from "styled-components";
import { titleBg } from "../../assets/images";
import { theme } from "../../styles/theme";

interface TitleProps {
  children: ReactNode;
}

const Title = ({ children }: TitleProps) => {
  return <StyledSelf>{children}</StyledSelf>;
};

export default Title;

const StyledSelf = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 72px;
  width: 512px;
  height: 160px;
  background-image: url(${titleBg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.7);
  font-size: ${theme.fontSizes.title};

  @media ${theme.mq.desktop} {
    margin-bottom: 3.75vw;
    width: 26.6667vw;
    height: 8.3333vw;
    font-size: 1.875vw;
    background-size: 100%;
  }
  @media ${theme.mq.mobile} {
    margin-bottom: 40px;
    width: 68.8889vw;
    height: 21.1111vw;
    font-size: 5vw;
  }
`;
