import React from 'react';
import styled from 'styled-components';
const CharacterCard = () => {
  return (
    <div>
      <PageContainer>
        <TitleContainer>
          <Title>Character summoner probability information</Title>
          <SubTitle>
            <div>*Seven parts are combined to feature characters</div>
            <br />
            <div>
              *The part grade is applied with a
              <span> 99.9% probability of grade 2</span> and
              <span> 0.1% probability of grade 3.</span>
            </div>
          </SubTitle>
        </TitleContainer>

        <Table>
          <Tbody>
            <Tr>
              <Th>Parts Type</Th>
              <Th>Class</Th>
              <Th>Grade</Th>
              <Th>Number of parts by grade</Th>
              <Th>Part Individual Probability</Th>
            </Tr>
            <Tr>
              <Td rowSpan={6}>Weapon</Td>
              <Td rowSpan={2}>Warrior</Td>
              <Td>2</Td>
              <Td>2</Td>
              <Td>16.67%</Td>
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>2</Td>
              <Td>16.67%</Td>
            </Tr>
            <Tr>
              <Td rowSpan={2}>Magician </Td>
              <Td>2</Td>
              <Td>2</Td>
              <Td>16.67%</Td>
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>2</Td>
              <Td>16.67%</Td>
            </Tr>
            <Tr>
              <Td rowSpan={2}>Hunter</Td>
              <Td>2</Td>
              <Td>2</Td>
              <Td>16.67%</Td>
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>2</Td>
              <Td>16.67%</Td>
            </Tr>
          </Tbody>
        </Table>
        <Table>
          <Tbody>
            <Tr>
              <Th>Parts Type</Th>
              <Th>Grade</Th>
              <Th>Number of parts by grade</Th>
              <Th>Part Individual Probability</Th>
            </Tr>
            <Tr>
              <Td rowSpan={2}>Eye</Td>
              <Td>2</Td>
              <Td>5</Td>
              <Td>20.00%</Td>
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>10</Td>
              <Td>10.00%</Td>
            </Tr>
            <Tr>
              <Td rowSpan={2}>Mouth</Td>
              <Td>2</Td>
              <Td>6</Td>
              <Td>16.67%</Td>
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>12</Td>
              <Td>8.33%</Td>
            </Tr>
            <Tr>
              <Td rowSpan={2}>Tattoo</Td>
              <Td>2</Td>
              <Td>6</Td>
              <Td>16.67%</Td>
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>12</Td>
              <Td>8.33%</Td>
            </Tr>
            <Tr>
              <Td rowSpan={2}>Hair</Td>
              <Td>2</Td>
              <Td>3</Td>
              <Td>33.33%</Td>
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>5</Td>
              <Td>20.00%</Td>
            </Tr>
            <Tr>
              <Td rowSpan={2}>Costume</Td>
              <Td>2</Td>
              <Td>2</Td>
              <Td>50.00%</Td>
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>5</Td>
              <Td>20.00%</Td>
            </Tr>
            <Tr>
              <Td rowSpan={2}>Accessory</Td>
              <Td>2</Td>
              <Td>-</Td>
              <Td>-</Td>
            </Tr>
            <Tr>
              <Td>3</Td>
              <Td>6</Td>
              <Td>16.67%</Td>
            </Tr>
          </Tbody>
        </Table>
      </PageContainer>
    </div>
  );
};

export default CharacterCard;

const PageContainer = styled.div`
  width: 80vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const Title = styled.div`
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #333;
  font-weight: 600;
  font-size: 2rem;
`;
const SubTitle = styled.div`
  width: 100%;
  text-align: start;
  padding: 20px 0px;
  font-size: 1.4rem;
  font-weight: 600;
  span:nth-of-type(1) {
    color: #55b333;
  }
  span:nth-of-type(2) {
    color: #8bc1d3;
  }
  ul {
    list-style: disc;
  }
  li {
    margin-left: 2rem;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 80px;
  width: 80%;
  font-weight: 600;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  font-size: 2rem;
  text-align: center;
`;

const Th = styled.th`
  border: 1px solid #000;
  padding: 10px 0;
  background-color: #222;
  color: #fff;
  font-size: 1.6rem;
`;

const Td = styled.td`
  padding: 5px 0px;
  height: 23px;
  font-size: 1.6rem;
  border: 1px solid #000;
  text-align: center;
  vertical-align: middle;
`;
const TitleContainer = styled.div`
  width: 80%;
`;
