export const AtkData = [
  { level: 1, values: [150, 300, 450, 600, 750] },
  { level: 2, values: [162, 318, 473, 629, 785] },
  { level: 3, values: [174, 336, 497, 659, 821] },
  { level: 4, values: [187, 355, 522, 690, 858] },
  { level: 5, values: [200, 374, 547, 721, 896] },
  { level: 6, values: [214, 394, 573, 753, 935] },
  { level: 7, values: [228, 415, 600, 786, 975] },
  { level: 8, values: [243, 436, 627, 820, 1016] },
  { level: 9, values: [258, 458, 655, 855, 1058] },
  { level: 10, values: [274, 480, 684, 890, 1100] },
  { level: 11, values: [290, 503, 713, 926, 1143] },
  { level: 12, values: [307, 526, 743, 963, 1187] },
  { level: 13, values: [324, 550, 774, 1101, 1232] },
  { level: 14, values: [342, 574, 805, 1039, 1278] },
  { level: 15, values: [360, 599, 837, 1078, 1325] },
  { level: 16, values: [378, 625, 870, 1118, 1373] },
  { level: 17, values: [397, 651, 903, 1159, 1422] },
  { level: 18, values: [416, 678, 937, 1201, 1472] },
  { level: 19, values: [436, 705, 972, 1243, 1523] },
  { level: 20, values: [456, 733, 1007, 1286, 1575] },
  { level: 21, values: [477, 761, 1043, 1330, 1628] },
  { level: 22, values: [498, 790, 1080, 1375, 1682] },
  { level: 23, values: [520, 820, 1117, 1421, 1732] },
  { level: 24, values: [542, 850, 1155, 1467, 1793] },
  { level: 25, values: [565, 881, 1194, 1514, 1850] },
  { level: 26, values: [588, 912, 1233, 1562, 1908] },
  { level: 27, values: [612, 944, 1273, 1611, 1967] },
  { level: 28, values: [636, 976, 1314, 1661, 2027] },
  { level: 29, values: [661, 1009, 1355, 1711, 2088] },
  { level: 30, values: [686, 1043, 1397, 1762, 2150] },
  { level: 31, values: [712, 1077, 1440, 1814, 2213] },
  { level: 32, values: [738, 1112, 1483, 1867, 2277] },
  { level: 33, values: [765, 1147, 1527, 1921, 2342] },
  { level: 34, values: [792, 1183, 1572, 1975, 2408] },
  { level: 35, values: [820, 1219, 1617, 2030, 2475] },
  { level: 36, values: [848, 1256, 1663, 2086, 2543] },
  { level: 37, values: [877, 1294, 1710, 2143, 2612] },
  { level: 38, values: [906, 1332, 1757, 2201, 2682] },
  { level: 39, values: [936, 1371, 1805, 2260, 2753] },
  { level: 40, values: [966, 1410, 1854, 2319, 2825] },
  { level: 41, values: [997, 1450, 1904, 2379, 2898] },
  { level: 42, values: [1028, 1490, 1954, 2440, 2972] },
  { level: 43, values: [1060, 1531, 2005, 2502, 3047] },
  { level: 44, values: [1092, 1573, 2057, 2565, 3123] },
  { level: 45, values: [1125, 1615, 2109, 2629, 3200] },
  { level: 46, values: [1158, 1658, 2162, 2693, 3278] },
  { level: 47, values: [1192, 1701, 2216, 2758, 3357] },
  { level: 48, values: [1226, 1745, 2270, 2824, 3437] },
  { level: 49, values: [1261, 1790, 2325, 2891, 3518] },
  { level: 50, values: [1296, 1835, 2381, 2959, 3600] },
];

export const HpData = [
  { level: 1, values: [6000, 7800, 9600, 11400, 13200] },
  { level: 2, values: [6077, 7896, 9717, 11541, 13367] },
  { level: 3, values: [6154, 7993, 9835, 11683, 13536] },
  { level: 4, values: [6232, 8090, 9954, 11826, 13706] },
  { level: 5, values: [6310, 8188, 10073, 11970, 13877] },
  { level: 6, values: [6389, 8287, 10193, 12115, 14049] },
  { level: 7, values: [6469, 8386, 10314, 12260, 14222] },
  { level: 8, values: [6548, 8486, 10436, 12406, 14396] },
  { level: 9, values: [6628, 8586, 10558, 12553, 14571] },
  { level: 10, values: [6709, 8687, 10681, 12701, 14748] },
  { level: 11, values: [6790, 8789, 10805, 12850, 14926] },
  { level: 12, values: [6872, 8891, 10930, 13000, 15105] },
  { level: 13, values: [6954, 8994, 11055, 13151, 15285] },
  { level: 14, values: [7037, 9097, 11181, 13303, 15466] },
  { level: 15, values: [7120, 9201, 11308, 13456, 15648] },
  { level: 16, values: [7204, 9306, 11436, 13610, 15832] },
  { level: 17, values: [7288, 9411, 11564, 13765, 16017] },
  { level: 18, values: [7373, 9517, 11693, 13921, 16203] },
  { level: 19, values: [7458, 9623, 11823, 14078, 16390] },
  { level: 20, values: [7544, 9730, 11954, 14236, 16578] },
  { level: 21, values: [7630, 9638, 12085, 14394, 16767] },
  { level: 22, values: [7717, 9946, 12217, 14553, 16958] },
  { level: 23, values: [7804, 10055, 12350, 14713, 17150] },
  { level: 24, values: [7892, 10164, 12484, 14874, 17343] },
  { level: 25, values: [7980, 10274, 12618, 15036, 17732] },
  { level: 26, values: [8069, 10385, 12753, 15199, 17537] },
  { level: 27, values: [8158, 10496, 12889, 15363, 17928] },
  { level: 28, values: [8248, 10608, 13026, 15528, 18126] },
  { level: 29, values: [8338, 10720, 13163, 15694, 18325] },
  { level: 30, values: [8429, 10833, 13301, 15961, 18525] },
  { level: 31, values: [8520, 10947, 13440, 16029, 18726] },
  { level: 32, values: [8612, 11061, 13580, 16198, 18928] },
  { level: 33, values: [8704, 11176, 13720, 16368, 19132] },
  { level: 34, values: [8797, 11292, 13861, 16539, 19337] },
  { level: 35, values: [8890, 11408, 14003, 16711, 19543] },
  { level: 36, values: [8984, 11525, 14146, 16884, 19750] },
  { level: 37, values: [9078, 11642, 14289, 17058, 19958] },
  { level: 38, values: [9173, 11760, 14433, 17232, 20167] },
  { level: 39, values: [9268, 11879, 14578, 17407, 20378] },
  { level: 40, values: [9364, 11998, 14724, 17583, 20590] },
  { level: 41, values: [9460, 12118, 14871, 17760, 20803] },
  { level: 42, values: [9557, 12238, 15018, 17938, 21017] },
  { level: 43, values: [9654, 12359, 15166, 18117, 21232] },
  { level: 44, values: [9752, 12481, 15315, 18297, 21449] },
  { level: 45, values: [9850, 12603, 15465, 18478, 21667] },
  { level: 46, values: [9949, 12726, 15615, 18660, 21886] },
  { level: 47, values: [10048, 12849, 15766, 18843, 22106] },
  { level: 48, values: [10148, 12973, 15918, 19027, 22327] },
  { level: 49, values: [10248, 13098, 16071, 19212, 22550] },
  { level: 50, values: [10349, 13223, 16224, 19398, 22774] },
];

export const DefenseData = [
  { level: 1, values: [200, 300, 400, 500, 600] },
  { level: 2, values: [214, 318, 422, 526, 631] },
  { level: 3, values: [228, 336, 445, 553, 663] },
  { level: 4, values: [243, 355, 468, 581, 696] },
  { level: 5, values: [258, 374, 492, 610, 730] },
  { level: 6, values: [274, 394, 517, 639, 765] },
  { level: 7, values: [290, 415, 542, 669, 801] },
  { level: 8, values: [307, 436, 568, 700, 837] },
  { level: 9, values: [324, 458, 595, 732, 874] },
  { level: 10, values: [342, 480, 622, 764, 912] },
  { level: 11, values: [360, 503, 650, 797, 951] },
  { level: 12, values: [378, 526, 679, 831, 991] },
  { level: 13, values: [397, 550, 708, 866, 1032] },
  { level: 14, values: [416, 574, 738, 902, 1074] },
  { level: 15, values: [436, 599, 769, 938, 1117] },
  { level: 16, values: [456, 625, 800, 975, 1161] },
  { level: 17, values: [477, 651, 832, 1013, 1206] },
  { level: 18, values: [498, 678, 864, 1052, 1252] },
  { level: 19, values: [520, 705, 897, 1091, 1299] },
  { level: 20, values: [542, 733, 931, 1131, 1347] },
  { level: 21, values: [565, 761, 965, 1172, 1395] },
  { level: 22, values: [588, 790, 1000, 1214, 1444] },
  { level: 23, values: [612, 820, 1036, 1257, 1494] },
  { level: 24, values: [636, 850, 1072, 1300, 1545] },
  { level: 25, values: [661, 881, 1109, 1344, 1597] },
  { level: 26, values: [786, 912, 1147, 1389, 1650] },
  { level: 27, values: [712, 944, 1185, 1435, 1704] },
  { level: 28, values: [738, 976, 1224, 1482, 1759] },
  { level: 29, values: [765, 1009, 1264, 1529, 1815] },
  { level: 30, values: [792, 1043, 1305, 1577, 1872] },
  { level: 31, values: [820, 1077, 1346, 1626, 1930] },
  { level: 32, values: [848, 1112, 1388, 1676, 1989] },
  { level: 33, values: [877, 1147, 1431, 1727, 2049] },
  { level: 34, values: [906, 1183, 1474, 1778, 2110] },
  { level: 35, values: [936, 1219, 1518, 1830, 2172] },
  { level: 36, values: [966, 1256, 1563, 1883, 2235] },
  { level: 37, values: [997, 1294, 1608, 1937, 2299] },
  { level: 38, values: [1028, 1332, 1654, 1992, 2364] },
  { level: 39, values: [1060, 1371, 1701, 2048, 2430] },
  { level: 40, values: [1092, 1410, 1748, 2104, 2497] },
  { level: 41, values: [1125, 1450, 1796, 2161, 2565] },
  { level: 42, values: [1158, 1490, 1845, 2219, 2634] },
  { level: 43, values: [1192, 1531, 1894, 2278, 2704] },
  { level: 44, values: [1226, 1573, 1944, 2338, 2775] },
  { level: 45, values: [1261, 1615, 1995, 2399, 2847] },
  { level: 46, values: [1296, 1658, 2046, 2460, 2920] },
  { level: 47, values: [1332, 1701, 2098, 2522, 2994] },
  { level: 48, values: [1368, 1745, 2151, 2585, 3069] },
  { level: 49, values: [1404, 1790, 2205, 2649, 3145] },
  { level: 50, values: [1441, 1835, 2259, 2714, 3222] },
];

// Critical Rate data
export const CriticalData = [
  { level: 1, values: [10, 10, 10, 10, 10] },
  { level: 2, values: [11, 11, 11, 11, 11] },
  { level: 3, values: [12, 12, 12, 12, 12] },
  { level: 4, values: [13, 13, 13, 13, 13] },
  { level: 5, values: [14, 14, 14, 14, 14] },
  { level: 6, values: [15, 15, 15, 15, 15] },
  { level: 7, values: [16, 16, 16, 16, 16] },
  { level: 8, values: [17, 17, 17, 17, 17] },
  { level: 9, values: [18, 18, 18, 18, 18] },
  { level: 10, values: [19, 19, 19, 19, 19] },
  { level: 11, values: [20, 20, 20, 20, 20] },
  { level: 12, values: [21, 21, 21, 21, 21] },
  { level: 13, values: [22, 22, 22, 22, 22] },
  { level: 14, values: [23, 23, 23, 23, 23] },
  { level: 15, values: [24, 24, 24, 24, 24] },
  { level: 16, values: [25, 25, 25, 25, 25] },
  { level: 17, values: [26, 26, 26, 26, 26] },
  { level: 18, values: [27, 27, 27, 27, 27] },
  { level: 19, values: [28, 28, 28, 28, 28] },
  { level: 20, values: [29, 29, 29, 29, 29] },
  { level: 21, values: [30, 30, 30, 30, 30] },
  { level: 22, values: [31, 31, 31, 31, 31] },
  { level: 23, values: [32, 32, 32, 32, 32] },
  { level: 24, values: [33, 33, 33, 33, 33] },
  { level: 25, values: [34, 34, 34, 34, 34] },
  { level: 26, values: [35, 35, 35, 35, 35] },
  { level: 27, values: [36, 36, 36, 36, 36] },
  { level: 28, values: [37, 37, 37, 37, 37] },
  { level: 29, values: [38, 38, 38, 38, 38] },
  { level: 30, values: [39, 39, 39, 39, 39] },
  { level: 31, values: [40, 40, 40, 40, 40] },
  { level: 32, values: [41, 41, 41, 41, 41] },
  { level: 33, values: [42, 42, 42, 42, 42] },
  { level: 34, values: [43, 43, 43, 43, 43] },
  { level: 35, values: [44, 44, 44, 44, 44] },
  { level: 36, values: [45, 45, 45, 45, 45] },
  { level: 37, values: [46, 46, 46, 46, 46] },
  { level: 38, values: [47, 47, 47, 47, 47] },
  { level: 39, values: [48, 48, 48, 48, 48] },
  { level: 40, values: [49, 49, 49, 49, 49] },
  { level: 41, values: [50, 50, 50, 50, 50] },
  { level: 42, values: [51, 51, 51, 51, 51] },
  { level: 43, values: [52, 52, 52, 52, 52] },
  { level: 44, values: [53, 53, 53, 53, 53] },
  { level: 45, values: [54, 54, 54, 54, 54] },
  { level: 46, values: [55, 55, 55, 55, 55] },
  { level: 47, values: [56, 56, 56, 56, 56] },
  { level: 48, values: [57, 57, 57, 57, 57] },
  { level: 49, values: [58, 58, 58, 58, 58] },
  { level: 50, values: [59, 59, 59, 59, 59] },
];

export const PartsData = [
  {
    part: 'Hair',
    levels: [
      { level: 'Base', atk: ['5~6', '9~10', '13~14', '17~18', '21~22'] },
      { level: 2, atk: ['7~9', '12~15', '17~21', '22~26', '27~32'] },
      { level: 3, atk: ['10~14', '16~22', '22~30', '28~37', '34~45'] },
      { level: 4, atk: ['13~21', '21~32', '28~42', '35~52', '42~62'] },
      { level: 5, atk: ['17~30', '27~45', '35~58', '43~71', '51~84'] },
      { level: 6, atk: ['22~42', '34~62', '43~79', '52~95', '61~111'] },
      { level: 7, atk: ['28~58', '42~84', '52~105', '62~125', '72~145'] },
      { level: 8, atk: ['35~79', '51~111', '62~137', '73~162', '85~186'] },
      { level: 9, atk: ['43~105', '61~145', '73~177', '86~207', '99~236'] },
      { level: 10, atk: ['52~137', '72~186', '86~225', '100~261', '114~296'] },
    ],
  },
  {
    part: 'Weapon Sword',
    levels: [
      { level: 'Base', atk: ['5~6', '5~6', '5~6', '5~6', '7~8'] },
      { level: 2, atk: ['7~9', '7~9', '7~9', '7~9', '9~12'] },
      { level: 3, atk: ['9~14', '9~14', '9~14', '9~14', '12~18'] },
      { level: 4, atk: ['12~21', '12~21', '12~21', '12~21', '15~26'] },
      { level: 5, atk: ['15~30', '15~30', '15~30', '15~30', '18~37'] },
      { level: 6, atk: ['18~42', '18~42', '18~42', '18~42', '22~52'] },
      { level: 7, atk: ['22~58', '22~58', '22~58', '22~58', '26~71'] },
      { level: 8, atk: ['26~79', '26~79', '26~79', '26~79', '31~95'] },
      { level: 9, atk: ['31~105', '31~105', '131~105', '31~105', '36~125'] },
      { level: 10, atk: ['36~137', '36~137', '36~137', '36~137', '42~162'] },
    ],
  },
  {
    part: 'Weapon Staff',
    levels: [
      { level: 'Base', atk: ['7~8', '7~8', '7~8', '9~10', '9~10'] },
      { level: 2, atk: ['9~12', '9~12', '9~12', '12~15', '12~15'] },
      { level: 3, atk: ['12~18', '12~18', '12~18', '15~22', '15~22'] },
      { level: 4, atk: ['15~26', '15~26', '15~26', '18~32', '18~32'] },
      { level: 5, atk: ['18~37', '18~37', '18~37', '22~45', '22~45'] },
      { level: 6, atk: ['22~52', '22~52', '22~52', '26~62', '26~62'] },
      { level: 7, atk: ['26~71', '26~71', '26~71', '31~84', '31~84'] },
      { level: 8, atk: ['31~95', '31~95', '31~95', '36~111', '36~111'] },
      { level: 9, atk: ['36~125', '36~125', '36~125', '42~145', '42~145'] },
      { level: 10, atk: ['42~162', '42~162', '42~162', '48~186', '48~186'] },
    ],
  },
  {
    part: 'Weapon Bow',
    levels: [
      { level: 'Base', atk: ['9~10', '9~10', '11~12', '11~12', '11~12'] },
      { level: 2, atk: ['12~15', '12~15', '14~18', '14~18', '14~18'] },
      { level: 3, atk: ['15~22', '15~22', '17~26', '17~26', '17~26'] },
      { level: 4, atk: ['18~32', '18~32', '21~37', '21~37', '21~37'] },
      { level: 5, atk: ['22~45', '22~45', '25~52', '25~52', '25~52'] },
      { level: 6, atk: ['26~62', '26~62', '30~71', '30~71', '30~71'] },
      { level: 7, atk: ['31~84', '31~84', '35~95', '35~95', '35~95'] },
      { level: 8, atk: ['36~111', '36~111', '40~125', '40~125', '40~125'] },
      { level: 9, atk: ['42~145', '42~145', '46~162', '46~162', '46~162'] },
      { level: 10, atk: ['48~186', '48~186', '52~207', '52~207', '52~207'] },
    ],
  },
  {
    part: 'Accessory',
    levels: [
      { level: 'Base', atk: ['-', '-', '2~3', '5~6', '8~9'] },
      { level: 2, atk: ['-', '-', '3~5', '7~10', '11~14'] },
      { level: 3, atk: ['-', '-', '4~8', '10~16', '15~22'] },
      { level: 4, atk: ['-', '-', '6~13', '13~25', '20~33'] },
      { level: 5, atk: ['-', '-', '8~20', '17~38', '26~49'] },
      { level: 6, atk: ['-', '-', '11~30', '22~56', '33~71'] },
      { level: 7, atk: ['-', '-', '15~45', '28~81', '41~101'] },
      { level: 8, atk: ['-', '-', '20~66', '25~114', '50~141'] },
      { level: 9, atk: ['-', '-', '26~94', '43~158', '60~193'] },
      { level: 10, atk: ['-', '-', '33~131', '52~215', '71~260'] },
    ],
  },
];

export const PartsHpData = [
  {
    part: 'Costume',
    levels: [
      {
        level: 'Base',
        atk: ['100~110', '150~165', '225~247', '337~370', '505~555'],
      },
      {
        level: 2,
        atk: ['125~166', '183~245', '269~247', '395~531', '583~784'],
      },
      {
        level: 3,
        atk: ['154~247', '221~358', '319~519', '460~751', '669~1092'],
      },
      {
        level: 4,
        atk: ['187~361', '264~515', '375~735', '533~1048', '764~1502'],
      },
      {
        level: 5,
        atk: ['225~519', '313~729', '438~1026', '614~1443', '868~2041'],
      },
      {
        level: 6,
        atk: ['269~735', '368~1018', '508~1414', '703~1964', '982~2743'],
      },
      {
        level: 7,
        atk: ['319~1026', '430~1404', '586~1926', '801~2643', '1106~3648'],
      },
      {
        level: 8,
        atk: ['375~1414', '672~2594', '62~137', '908~3520', '1241~4805'],
      },
      {
        level: 9,
        atk: ['438~1926', '767~3457', '73~177', '1025~4642', '1387~6271'],
      },
      {
        level: 10,
        atk: ['508~2594', '661~3435', '871~4561', '1153~6065', '1545~8114'],
      },
    ],
  },

  {
    part: 'Bag',
    levels: [
      { level: 'Base', atk: ['-', '-', '-', '7~8', '10~11'] },
      { level: 2, atk: ['-', '-', '-', '11~14', '16~19'] },
      { level: 3, atk: ['-', '-', '-', '17~24', '25~33'] },
      { level: 4, atk: ['-', '-', '-', '26~41', '38~56'] },
      { level: 5, atk: ['-', '-', '-', '39~69', '56~93'] },
      { level: 6, atk: ['-', '-', '-', '57~114', '81~152'] },
      { level: 7, atk: ['-', '-', '-', '82~184', '114~152'] },
      { level: 8, atk: ['-', '-', '-', '115~293', '158~383'] },
      { level: 9, atk: ['-', '-', '-', '159~459', '215~594'] },
      { level: 10, atk: ['-', '-', '-', '216~707', '288~907'] },
    ],
  },
];

export const PartsDefenseData = [
  {
    part: 'Tattoo',
    levels: [
      { level: 'Base', atk: ['5~6', '9~10', '13~14', '17~18', '21~22'] },
      { level: 2, atk: ['7~9', '12~15', '17~21', '22~26', '27~32'] },
      { level: 3, atk: ['10~14', '16~22', '22~30', '28~37', '34~45'] },
      { level: 4, atk: ['13~21', '21~32', '28~42', '35~52', '42~62'] },
      { level: 5, atk: ['17~30', '27~45', '35~58', '43~71', '51~84'] },
      { level: 6, atk: ['22~42', '34~62', '43~79', '52~95', '61~111'] },
      { level: 7, atk: ['28~58', '42~84', '52~105', '62~125', '72~145'] },
      { level: 8, atk: ['35~79', '51~111', '62~137', '73~162', '85~186'] },
      { level: 9, atk: ['43~105', '61~145', '73~177', '86~207', '99~236'] },
      { level: 10, atk: ['52~137', '72~186', '86~225', '100~261', '114~296'] },
    ],
  },
  {
    part: 'Weapon Sword',
    levels: [
      { level: 'Base', atk: ['5~6', '5~6', '5~6', '5~6', '7~8'] },
      { level: 2, atk: ['7~9', '7~9', '7~9', '7~9', '9~12'] },
      { level: 3, atk: ['9~14', '9~14', '9~14', '9~14', '12~18'] },
      { level: 4, atk: ['12~21', '12~21', '12~21', '12~21', '15~26'] },
      { level: 5, atk: ['15~30', '15~30', '15~30', '15~30', '18~37'] },
      { level: 6, atk: ['18~42', '18~42', '18~42', '18~42', '22~52'] },
      { level: 7, atk: ['22~58', '22~58', '22~58', '22~58', '26~71'] },
      { level: 8, atk: ['26~79', '26~79', '26~79', '26~79', '31~95'] },
      { level: 9, atk: ['31~105', '31~105', '131~105', '31~105', '36~125'] },
      { level: 10, atk: ['36~137', '36~137', '36~137', '36~137', '42~162'] },
    ],
  },
  {
    part: 'Weapon Staff',
    levels: [
      { level: 'Base', atk: ['7~8', '7~8', '7~8', '9~10', '9~10'] },
      { level: 2, atk: ['9~12', '9~12', '9~12', '12~15', '12~15'] },
      { level: 3, atk: ['12~18', '12~18', '12~18', '15~22', '15~22'] },
      { level: 4, atk: ['15~26', '15~26', '15~26', '18~32', '18~32'] },
      { level: 5, atk: ['18~37', '18~37', '18~37', '22~45', '22~45'] },
      { level: 6, atk: ['22~52', '22~52', '22~52', '26~62', '26~62'] },
      { level: 7, atk: ['26~71', '26~71', '26~71', '31~84', '31~84'] },
      { level: 8, atk: ['31~95', '31~95', '31~95', '36~111', '36~111'] },
      { level: 9, atk: ['36~125', '36~125', '36~125', '42~145', '42~145'] },
      { level: 10, atk: ['42~162', '42~162', '42~162', '48~186', '48~186'] },
    ],
  },
  {
    part: 'Weapon Bow',
    levels: [
      { level: 'Base', atk: ['9~10', '9~10', '11~12', '11~12', '11~12'] },
      { level: 2, atk: ['12~15', '12~15', '14~18', '14~18', '14~18'] },
      { level: 3, atk: ['15~22', '15~22', '17~26', '17~26', '17~26'] },
      { level: 4, atk: ['18~32', '18~32', '21~37', '21~37', '21~37'] },
      { level: 5, atk: ['22~45', '22~45', '25~52', '25~52', '25~52'] },
      { level: 6, atk: ['26~62', '26~62', '30~71', '30~71', '30~71'] },
      { level: 7, atk: ['31~84', '31~84', '35~95', '35~95', '35~95'] },
      { level: 8, atk: ['36~111', '36~111', '40~125', '40~125', '40~125'] },
      { level: 9, atk: ['42~145', '42~145', '46~162', '46~162', '46~162'] },
      { level: 10, atk: ['48~186', '48~186', '52~207', '52~207', '52~207'] },
    ],
  },
  {
    part: 'Bag',
    levels: [
      { level: 'Base', atk: ['-', '-', '-', '7~8', '10~11'] },
      { level: 2, atk: ['-', '-', '-', '11~14', '16~19'] },
      { level: 3, atk: ['-', '-', '-', '17~24', '25~33'] },
      { level: 4, atk: ['-', '-', '-', '26~41', '38~56'] },
      { level: 5, atk: ['-', '-', '-', '39~69', '56~93'] },
      { level: 6, atk: ['-', '-', '-', '57~114', '81~152'] },
      { level: 7, atk: ['-', '-', '-', '82~184', '114~152'] },
      { level: 8, atk: ['-', '-', '-', '115~293', '158~383'] },
      { level: 9, atk: ['-', '-', '-', '159~459', '215~594'] },
      { level: 10, atk: ['-', '-', '-', '216~707', '288~907'] },
    ],
  },
];

export const PartsCriticalData = [
  {
    part: 'Mouth',
    levels: [
      { level: 'Base', atk: ['5~6', '7~8', '9~10', '11~12', '13~14'] },
      { level: 2, atk: ['7~10', '9~13', '17~21', '14~20', '17~23'] },
      { level: 3, atk: ['9~17', '12~22', '22~30', '18~33', '21~37'] },
      { level: 4, atk: ['12~28', '16~36', '28~42', '23~53', '26~59'] },
      { level: 5, atk: ['16~45', '20~57', '35~58', '28~83', '32~92'] },
      { level: 6, atk: ['20~71', '25~89', '43~79', '34~127', '38~140'] },
      { level: 7, atk: ['25~110', '31~136', '52~105', '41~191', '45~210'] },
      { level: 8, atk: ['31~166', '37~204', '62~137', '49~282', '53~309'] },
      { level: 9, atk: ['37~247', '44~300', '73~177', '57~410', '62~447'] },
      { level: 10, atk: ['44~361', '52~434', '86~225', '66~586', '72~637'] },
    ],
  },

  {
    part: 'Accessory',
    levels: [
      { level: 'Base', atk: ['-', '-', '5~6', '9~10', '13~14'] },
      { level: 2, atk: ['-', '-', '7~10', '12~16', '17~22'] },
      { level: 3, atk: ['-', '-', '10~16', '16~25', '22~33'] },
      { level: 4, atk: ['-', '-', '13~25', '21~38', '28~49'] },
      { level: 5, atk: ['-', '-', '17~38', '27~56', '35~71'] },
      { level: 6, atk: ['-', '-', '22~56', '34~81', '43~101'] },
      { level: 7, atk: ['-', '-', '28~81', '42~114', '52~141'] },
      { level: 8, atk: ['-', '-', '25~114', '51~158', '62~193'] },
      { level: 9, atk: ['-', '-', '43~158', '61~215', '73~260'] },
      { level: 10, atk: ['-', '-', '52~215', '72~288', '86~345'] },
    ],
  },
];

export const PartsAtkData = [
  {
    part: 'Eye',
    levels: [
      { level: 'Base', atk: ['10~20', '30~40', '50~60', '70~80', '90~100'] },
      { level: 2, atk: ['17~35', '50~69', '82~103', '113~136', '144~169'] },
      { level: 3, atk: ['29~61', '82~118', '132~174', '180~227', '227~281'] },
      { level: 4, atk: ['49~104', '132~198', '209~289', '281~374', '351~459'] },
      { level: 5, atk: ['80~175', '209~327', '325~472', '432~606', '535~740'] },
      {
        level: 6,
        atk: ['129~290', '325~532', '497~760', '653~968', '803~1176'],
      },
      {
        level: 7,
        atk: ['204~474', '497~853', '748~1207', '973~1526', '1187~1843'],
      },
      {
        level: 8,
        atk: ['317~763', '748~1350', '1109~1891', '1429~2374', '1731~2852'],
      },
      {
        level: 9,
        atk: ['485~1211', '1109~2108', '1621~2925', '2071~3648', '2493~4361'],
      },
      {
        level: 10,
        atk: ['730~1897', '1621~3250', '2339~4469', '2965~5540', '3547~6591'],
      },
    ],
  },
  {
    part: 'Weapon Sword',
    levels: [
      { level: 'Base', atk: ['30~40', '30~40', '30~40', '30~40', '50~60'] },
      { level: 2, atk: ['40~63', '40~63', '40~63', '40~63', '65~92'] },
      { level: 3, atk: ['53~96', '53~96', '53~96', '53~96', '83~138'] },
      { level: 4, atk: ['69~144', '69~144', '69~144', '69~144', '105~203'] },
      { level: 5, atk: ['88~212', '88~212', '88~212', '88~212', '130~294'] },
      {
        level: 6,
        atk: ['110~306', '110~306', '110~306', '110~306', '160~419'],
      },
      {
        level: 7,
        atk: ['136~435', '136~435', '136~435', '136~435', '194~588'],
      },
      {
        level: 8,
        atk: ['167~609', '167~609', '167~609', '167~609', '233~813'],
      },
      {
        level: 9,
        atk: ['202~841', '202~841', '202~841', '202~8415', '278~1110'],
      },
      {
        level: 10,
        atk: ['243~1147', '243~1147', '243~1147', '243~1147', '329~1497'],
      },
    ],
  },
  {
    part: 'Weapon Staff',
    levels: [
      { level: 'Base', atk: ['50~60', '50~60', '50~60', '70~80', '70~80'] },
      { level: 2, atk: ['65~92', '65~92', '65~92', '89~121', '89~121'] },
      { level: 3, atk: ['83~138', '83~138', '83~138', '112~179', '112~179'] },
      {
        level: 4,
        atk: ['105~203', '105~203', '105~203', '139~261', '139~261'],
      },
      {
        level: 5,
        atk: ['130~294', '130~294', '130~294', '170~374', '170~374'],
      },
      {
        level: 6,
        atk: ['160~419', '160~419', '160~419', '206~527', '206~527'],
      },
      {
        level: 7,
        atk: ['194~588', '194~588', '194~588', '247~732', '247~732'],
      },
      {
        level: 8,
        atk: ['233~813', '233~813', '233~813', '294~1004', '294~1004'],
      },
      {
        level: 9,
        atk: ['278~1110', '278~1110', '278~1110', '347~1360', '347~1360'],
      },
      {
        level: 10,
        atk: ['329~1497', '329~1497', '329~1497', '407~1820', '407~1820'],
      },
    ],
  },
  {
    part: 'Weapon Bow',
    levels: [
      { level: 'Base', atk: ['70~80', '70~80', '90~100', '90~100', '90~100'] },
      { level: 2, atk: ['89~121', '89~121', '113~150', '113~150', '113~150'] },
      {
        level: 3,
        atk: ['112~179', '112~179', '113~150', '113~150', '113~150'],
      },
      {
        level: 4,
        atk: ['139~261', '139~261', '140~220', '140~220', '140~220'],
      },
      {
        level: 5,
        atk: ['170~374', '170~374', '171~317', '171~317', '171~317'],
      },
      {
        level: 6,
        atk: ['206~527', '206~527', '207~450', '207~450', '207~450'],
      },
      {
        level: 7,
        atk: ['247~732', '247~732', '295~868', '295~868', '295~868'],
      },
      {
        level: 8,
        atk: ['294~1004', '294~1004', '348~1182', '348~1182', '348~1182'],
      },

      {
        level: 9,
        atk: ['347~1360', '347~1360', '408~1591', '408~1591', '408~1591'],
      },
      {
        level: 10,
        atk: ['407~1820', '407~1820', '475~2117', '475~2117', '475~2117'],
      },
    ],
  },
];

export const skillData = [
  { name: 'Rage', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'Awakening', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'Weakness Attack', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'Extra Attack', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'Reduce Boss Damage', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'Iron Skin', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'Damage Reflection', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'HP Recovery', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'Get AP', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'Life after each Kill', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'Fair winds', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'Experience Gained', probabilities: [7.692, 8.333, 9.091, 10.0] },
  { name: 'AP charge', probabilities: [7.692, 8.333, 9.091, 10.0] },
];
