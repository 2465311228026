import { useState,Dispatch, SetStateAction } from "react";

function useModal(initialValue:boolean) {
    const [isOpen, setIsOpen] = useState(initialValue);
    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };


    const switchStatus = () => {
        setIsOpen((prev: boolean) => !prev);
    };


    return [isOpen, openModal, closeModal] as const;
}

export default useModal;