export const SLIDE_DATA = [
    {
        id:1,
        job: "Warrior",
        title: "Scared to come to me? \nThen I’ll go to you!",
        description : "Quickly dash into enemy lines and engage in dynamic battles.",
        stat : {
            Health: 60,
            Attack: 60,
            Speed: 60,
            Tech: 80
        }
    },
    {
        id:2,
        job: "Magician",
        title: "I’ll send you to hell with \none shot!",
        description : "A long-ranged class that uses powerful magic. With magical skills, this class can prevent enemies from getting too close and take down multiple targets at once.",
        stat : {
            Health: 50,
            Attack: 100,
            Speed: 40,
            Tech: 70
        }
    },
    {
        id:3,
        job: "Hunter",
        title: "You won’t come close to me!",
        description : "A ranged class that inflicts physical damage.  While each attack damage may not be much, it accumulates large amounts of damage with quick attack speed and maintains distance in battle.",
        stat : {
            Health: 40,
            Attack: 55,
            Speed: 55,
            Tech: 100
        }
    },
    {
        id:4,
        job: "Guardian",
        title: "My shield cannot be broken!",
        description : "The class with the greatest amount of physical strength for high survivability. The sharp and heavy attack of the lance causes enemies to tremble with fear.",
        stat : {
            Health: 100,
            Attack: 60,
            Speed: 40,
            Tech: 40
        }
    },

]