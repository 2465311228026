import React from 'react';
import styled from "styled-components";
import {btnArrowActive, btnArrowOff, btnArrowOn} from "../../assets/images";
import {theme} from "../../styles/theme";

interface PrevArrowProps {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    currentIndex?: number;
}
export default function PrevArrow({onClick ,currentIndex} :PrevArrowProps){
    const isFirstSlide = currentIndex === 0;
    return (
        <PrevBtn className={`prev-arrow ${isFirstSlide ? 'disabled' : ''}`} onClick={onClick}></PrevBtn>
    );
};

const PrevBtn = styled.div`
  position: absolute;
  top: 50%;
  left: -137px;
  transform: translateY(-50%);
  width: 96px;
  height: 96px;
  background-image: url(${btnArrowActive});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all ease 0.2s;
  z-index: 9999;
  &:hover {
    background-image: url(${btnArrowOn});
    cursor: pointer;
  }
  &.disabled {
    background-image: url(${btnArrowOff});
    &:hover {
      background-image: url(${btnArrowOff});
      cursor: unset;
    }
  }
  @media ${theme.mq.desktop}{
    left: -7.1354vw;
    width: 5vw;
    height: 5vw;
  }
  @media ${theme.mq.mobile}{
    top: 83.0556vw;
    left: -3.3333vw;
    width: 9.4444vw;
    height: 9.4444vw;
    &:hover {
      background-image: url(${btnArrowActive});
      cursor: pointer;
    }
    &.disabled {
      background-image: none;
      &:hover {
        background-image: none;
        cursor: unset;
      }
    }
  }
`