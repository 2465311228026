import React from "react";
import styled from "styled-components";
import { rewardCardBg, rewardCardBgM } from "../../assets/images";
import { theme } from "../../styles/theme";

interface RewardCardProps {
  value: {
    id: number;
    price: string;
    imgSrc: string;
    text: string;
  };
}
const RewardCard = ({ value }: RewardCardProps) => {
  const { id, price, imgSrc, text } = value;
  return (
    <StyledSelf>
      <ImgWrap>
        <img src={imgSrc} className={`img${id}`} alt={text} />
      </ImgWrap>
      <TextWrap>
        <PriceText>
          <p className={"outlinedText"} data-text={price}>
            {price}
          </p>
        </PriceText>
        <TitleText className={`list${id}`}>{text}</TitleText>
      </TextWrap>
    </StyledSelf>
  );
};

export default RewardCard;

const StyledSelf = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 0 20px;
  width: 264px;
  height: 380px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${rewardCardBg});
  background-size: cover;

  @media ${theme.mq.desktop} {
    padding: 2.2917vw 0 1.0417vw;
    width: 13.75vw;
    height: 19.7917vw;
  }
  @media ${theme.mq.mobile} {
    position: static;
    flex-direction: row;
    padding: 0 8.3333vw;
    width: 88.8889vw;
    height: 28.8889vw;
    /* width: 320px;
    height: 104px; */
    background-image: url(${rewardCardBgM});
  }
`;

const ImgWrap = styled.div`
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  width: 220px;
  img {
    display: block;
    width: 100%;
  }
  @media ${theme.mq.desktop} {
    top: 4.6875vw;
    width: 11.4583vw;
  }
  @media ${theme.mq.mobile} {
    position: static;
    transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26.6667vw;
    height: 26.6667vw;
    img {
      width: 100%;
      &.img1,
      &.img2 {
        width: 120%;
      }
      &.img3 {
        width: 110%;
      }
    }
  }
`;
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 235px;
  @media ${theme.mq.desktop} {
    gap: 12.2396vw;
  }
  @media ${theme.mq.mobile} {
    margin-top: 2.7778vw;
    gap: 3.3333vw;
    flex: 1;
  }
`;
const PriceText = styled.div`
  position: relative;
  font-size: 3.2rem;
  height: 30px;
  display: inline-block;
  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
    top: 45%;
    width: 8px;
    height: 8px;
    border-radius: 1px;
    background-color: #fde6b5;
    transform: rotate(45deg) translateY(-55%);
  }
  &:before {
    left: -33px;
  }
  &:after {
    left: calc(100% + 21px);
  }

  @media ${theme.mq.desktop} {
    font-size: 1.6667vw;
    height: 1.5625vw;
    &:before,
    &:after {
      width: 0.4167vw;
      height: 0.4167vw;
    }
    &:before {
      left: -1.7188vw;
    }
    &:after {
      left: calc(100% + 1.0938vw);
    }
  }
  @media ${theme.mq.mobile} {
    font-size: 6.6667vw;
    height: 7.2222vw;

    &:before,
    &:after {
      top: 40%;
      width: 1.6667vw;
      height: 1.6667vw;
    }
    &:before {
      left: -5.5556vw;
    }
    &:after {
      left: calc(100% + 2.9167vw);
    }
  }
`;
const TitleText = styled.p`
  font-size: 2.4rem;
  font-weight: 500;
  text-shadow: 0px 0px 10px #00282c;
  white-space: pre-line;
  text-align: center;
  line-height: 1.33;
  &.list4,
  &.list5 {
    margin-top: -14px;
  }
  @media ${theme.mq.desktop} {
    font-size: 1.25vw;
    line-height: 1.33;
    &.list4,
    &.list5 {
      margin-top: -0.7292vw;
    }
  }
  @media ${theme.mq.mobile} {
    font-size: 3.8889vw;
    line-height: 1.28;
    &.list4,
    &.list5 {
      margin-top: -2.2222vw;
    }
  }
`;
