import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import {theme} from "./theme";

export const GlobalStyle = createGlobalStyle`
${reset}


html, body, *  {
  font-family: wemakeprice, sans-serif;
}
html {
  font-size: 62.5%;
  .onlyPc {
    display: block;
  }
  .onlyMobile {
    display: none;
  }
  @media ${({ theme }) => theme.mq.mobile} {
      font-size: 37.5%;
    .onlyMobile {
      display: block;
    }
    .onlyPc {
      display: none;
    }
  }
}
* {
  box-sizing: border-box;
  line-height: normal;
}
body {
    overflow-y: scroll !important;
    background-color: ${({ theme }) => theme.colors.mainBg};
    color: ${({ theme }) => theme.colors.text};
    ::-webkit-scrollbar {
        display: none;
    }
    font-size: 2.0rem;
    font-weight: 400;
    font-family: wemakeprice, sans-serif;
    
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.text};
    }
    button {
      cursor: pointer;
      border: none;
      background: none;
      color: inherit;
    }
    ul, ol {
      list-style: none;
    }
  .outlinedText {
    font-family: wemakeprice, sans-serif;
    position: relative;
    &::before {
      position: absolute;
      content: attr(data-text);
      top: 0;
      left: 0;
      --stroke-width: 3px;
      --stroke-color: #00282C;
      text-shadow:
              calc(var(--stroke-width) * 1) 0 0 var(--stroke-color),
              calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color),
              calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color),
              calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color),
              0 calc(var(--stroke-width) * 1) 0 var(--stroke-color),
              calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color),
              calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color),
              calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color),
              calc(var(--stroke-width) * -1) 0 0 var(--stroke-color),
              calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color),
              calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color),
              calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color),
              0 calc(var(--stroke-width) * -1) 0 var(--stroke-color),
              calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color),
              calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color),
              calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color);
    }
  }
  .onlyPc {
    display: block;
  }
  .onlyMobile {
    display: none;
  }
  @media ${theme.mq.desktop}{
    .outlinedText {
      &::before {
        --stroke-width: 0.1563vw;
      }
    }
  }
  @media ${theme.mq.mobile}{
    .outlinedText {
      &::before {
        --stroke-width: 0.5556vw;
      }
    }
    .onlyPc {
      display: none;
    }
    .onlyMobile {
      display: block;
    }
  }
  }
`;
