import React, {ReactNode} from 'react';
import styled from "styled-components";
import {cardClass, cardFrame, cardFrameM, cardPublic, textDeco} from "../../assets/images";
import {theme} from "../../styles/theme";

interface  CardBoxProps {
    children : ReactNode;
}
interface CardData {
    [key: string]: {
        imgSrc: string;
        description: string;
    };
}
const CARD_DATA:CardData = {
    "Public Card" : {
        imgSrc: `${cardPublic}`,
        description: "Public cards are common \ncards that all classes can use.",
    },
    "Class Card" : {
        imgSrc: `${cardClass}`,
        description: "Class cards are job cards that can \nonly be used by a specific class.",
    }
}
const CardBox = ({children} :CardBoxProps) => {
    return (
        <CardBoxWrap>
            <TitleWrap >
                <img src={textDeco} className={"deco left"} />
                <p className={"outlinedText"} data-text={children}>{children}</p>
                <img src={textDeco} className={"deco right"} />
            </TitleWrap>
            <CardContent>
                <ImgWrap>
                    <img src={CARD_DATA[children as string].imgSrc} alt={children as string}/>
                </ImgWrap>
                <ContentText>{CARD_DATA[children as string].description}</ContentText>
            </CardContent>
        </CardBoxWrap>
    );
};

export default CardBox;

const CardBoxWrap = styled.div`
    text-align: center;
`
const TitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
  margin-bottom: 32px;
  .outlinedText {
    font-size: 3.2rem;
  }
  .deco {
    width: 38px;
    height: 32px;
    margin-top: -5px;
    &.left {
      transform: scaleX(-1);
    }
  }
  
  @media ${theme.mq.desktop}{
    gap: 1.4583vw;
    margin-bottom: 1.6667vw;
    .outlinedText {
      font-size: 1.6667vw;
    }
    .deco {
      width: 1.9792vw;
      height: 1.6667vw;
      margin-top: -0.2604vw;
    }
  }
  
  @media ${theme.mq.mobile}{
    gap: 3.3333vw;
    margin-bottom: 4.4444vw;
    .outlinedText{
      font-size: 5vw;
    }
    .deco {
      margin-top: -1.3889vw;
      width: 6.6667vw;
      height: 5.5556vw;
    }
  }
`;
const CardContent = styled.div`
  background-image: url(${cardFrame});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 570px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  @media ${theme.mq.desktop}{
    width: 29.6875vw;
    height: 26.0417vw;
  }
  @media ${theme.mq.mobile}{
    background-image: url(${cardFrameM});
    width: 88.8889vw;
    height: 91.6667vw;
  }
`;
const ImgWrap = styled.div`
  padding: 32px 0 16px;
  width: 490px;
  img {
    width: 100%;
  }
  @media ${theme.mq.desktop}{
    padding: 1.6667vw 0 0.8333vw;
    width: 25.5208vw;
  }
  @media ${theme.mq.mobile}{
    padding: 6.9444vw 0 5.55564vw;
    width: 72.2222vw;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 110%;
    }
  }
`;
const ContentText = styled.p`
  white-space: pre-line;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.60);
  font-size: 2.4rem;
  line-height: 1.33;
  
  @media ${theme.mq.desktop}{
    font-size: 1.25vw;
  }
  @media ${theme.mq.mobile}{
    font-size: 4.4444vw;
    line-height: 1.62;
  }
`;