import React from 'react';
import styled from 'styled-components';
import { AtkData, CriticalData, HpData, DefenseData } from './data';

const CharacterLevel = () => {
  return (
    <PageContainer>
      <TitleContainer>
        <Title>Character LevelUp</Title>
        <SubTitle>
          <div>
            * The capability value changes to a fixed value when leveled up
          </div>
        </SubTitle>
      </TitleContainer>

      <Table>
        <Tr>
          <LevelTh rowSpan={2}>Level</LevelTh>
          <Th colSpan={5}>Atk</Th>
        </Tr>
        <GradeTr>
          <Td>Common</Td>
          <Td>Uncommon</Td>
          <Td>Rare</Td>
          <Td>Unique</Td>
          <Td>Epic</Td>
        </GradeTr>

        <tbody>
          {AtkData.map((item) => (
            <Tr key={item.level}>
              <Td>{item.level}</Td>
              {item.values.map((value, index) => (
                <Td key={index}>{value}</Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </Table>

      <Table>
        <Tr>
          <LevelTh rowSpan={2}>Level</LevelTh>
          <Th colSpan={5}>Hp</Th>
        </Tr>
        <GradeTr>
          <Td>Common</Td>
          <Td>Uncommon</Td>
          <Td>Rare</Td>
          <Td>Unique</Td>
          <Td>Epic</Td>
        </GradeTr>

        <tbody>
          {HpData.map((item) => (
            <Tr key={item.level}>
              <Td>{item.level}</Td>
              {item.values.map((value, index) => (
                <Td key={index}>{value}</Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </Table>

      <Table>
        <Tr>
          <LevelTh rowSpan={2}>Level</LevelTh>
          <Th colSpan={5}>Defense</Th>
        </Tr>
        <GradeTr>
          <Td>Common</Td>
          <Td>Uncommon</Td>
          <Td>Rare</Td>
          <Td>Unique</Td>
          <Td>Epic</Td>
        </GradeTr>

        <tbody>
          {DefenseData.map((item) => (
            <Tr key={item.level}>
              <Td>{item.level}</Td>
              {item.values.map((value, index) => (
                <Td key={index}>{value}</Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </Table>

      <Table>
        <Tr>
          <LevelTh rowSpan={2}>Level</LevelTh>
          <Th colSpan={5}>Critical Rate</Th>
        </Tr>
        <GradeTr>
          <Td>Common</Td>
          <Td>Uncommon</Td>
          <Td>Rare</Td>
          <Td>Unique</Td>
          <Td>Epic</Td>
        </GradeTr>

        <tbody>
          {CriticalData.map((item) => (
            <Tr key={item.level}>
              <Td>{item.level}</Td>
              {item.values.map((value, index) => (
                <Td key={index}>{value}</Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </Table>
    </PageContainer>
  );
};

export default CharacterLevel;

const PageContainer = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Title = styled.div`
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #333;
  font-weight: 600;
  font-size: 2rem;
`;

const SubTitle = styled.div`
  width: 100%;
  text-align: start;
  padding: 20px 0px;
  font-size: 1.4rem;
  font-weight: 600;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 80px;
  width: 80%;
  font-weight: 600;
`;

const Th = styled.th`
  border: 1px solid #000;
  padding: 5px 0px;
  background-color: #222;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
`;

const LevelTh = styled.th`
  width: calc(100% / 6);
  border: 1px solid #000;
  padding: 5px 0px;
  background-color: #222;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  vertical-align: middle;
`;

const Td = styled.td`
  padding: 5px 0px;
  font-size: 1.6rem;
  border: 1px solid #000;
  text-align: center;
  vertical-align: middle;
`;

const GradeTr = styled.tr`
  background-color: lightgray;
  width: calc(100% / 6);
  color: #fff;

  & > td:nth-child(2) {
    background-color: #55b333;
    width: calc(100% / 6);
  }
  & > td:nth-child(3) {
    background-color: #4a9cdb;
    width: calc(100% / 6);
  }
  & > td:nth-child(4) {
    background-color: #ebb820;
    width: calc(100% / 6);
  }
  & > td:nth-child(5) {
    background-color: #ff5656;
    width: calc(100% / 6);
  }
`;

const Tr = styled.tr`
  font-size: 2rem;
  text-align: center;
`;

const TitleContainer = styled.div`
  width: 80%;
`;
