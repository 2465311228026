import React from 'react';
import styled from "styled-components";
import {
    btnApple,
    btnArrowActive,
    btnGoogle,
    logoMain,
    mainBg,
    mainBgM,
    preRegistrationTitle,
    tokenImg2
} from "../../assets/images";
import {theme} from "../../styles/theme";
import useModal from "../../hook/useModal";
import CommonModal from "../../components/Modal/commonModal";
import Btn from "../../components/Btn";
import {useMediaQuery} from "react-responsive";
import { Link } from "react-scroll";

const Intro = () => {
    const [isModal, openModal, closeModal] = useModal(true);
    const handleModal = () => {
        if(!isModal){
            openModal();
        }else {
            closeModal();
        }
    }
    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    });
    return (
        <IntroWrap id={"0"}>
            <LogoWrap><img src={logoMain} alt="superkola logo"/></LogoWrap>
            <RegistrationWrap>
                <ImgWrap><img src={preRegistrationTitle} alt=""/></ImgWrap>
                <BtnWrap>
                    <button><a href={"https://play.google.com/store/apps/details?id=com.carrieverse.superkola"} target={"_blank"}><img src={btnGoogle} alt="구글 스토어"/></a></button>
                    <button><img src={btnApple} alt="애플 스토어"/><span className={"outlinedText"} data-text={"Coming Soon"}>Coming Soon</span></button>
                </BtnWrap>
                {isMobile && <ScrollBtn><Link to={"1"} smooth={true}><img src={btnArrowActive} alt=""/></Link></ScrollBtn>}
            </RegistrationWrap>
            {/*{isModal && (*/}
            {/*    <CommonModal*/}
            {/*        width={isMobile? 66.6667 : 448}*/}
            {/*        minHeight={isMobile? 99.4444 : 574}*/}
            {/*        left={isMobile? "50%" : "24px"}*/}
            {/*        top={isMobile? "50%" : "96px"}*/}
            {/*        transform={isMobile? "" : "none"}*/}
            {/*        modalIsOpen={isModal}*/}
            {/*        closeModal={closeModal}*/}
            {/*        closeIcon={true}*/}
            {/*        scroll={true}*/}
            {/*    >*/}
            {/*        <ModalText>*/}
            {/*            <Title className={"outlinedText"} data-text={"CBT"}>CBT</Title>*/}
            {/*            <SubTitle className={"outlinedText subTitle"} data-text={"OPEN EVENT"}>OPEN EVENT</SubTitle>*/}
            {/*            <ModalImgWrap><img src={tokenImg2} alt=""/></ModalImgWrap>*/}
            {/*            <Btn width={isMobile? 55.555 : 352} fontSize={isMobile? 1.4 : 2.0}>Participate in</Btn>*/}
            {/*        </ModalText>*/}
            {/*    </CommonModal>*/}
            {/*)}*/}
        </IntroWrap>
    );
};

export default Intro;

const IntroWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url(${mainBg});
  background-size: cover;
  @media ${theme.mq.mobile}{
    background-image: url(${mainBgM});
  }
`;
const LogoWrap = styled.div`
  position: absolute;
  top: 165px;
  left: 50%;
  transform: translateX(-50%);
  width: 586px;
  img {
    width: 100%;
  }
  @media ${theme.mq.mobile}{
    top: 136px;
    width: 64.4444vw;
  }
`;

const RegistrationWrap = styled.div`
  position: absolute;
  bottom: 160px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${theme.mq.desktop}{
    bottom: 8.3333vw;
  }
  @media ${theme.mq.mobile}{
    bottom: 5vh;
  }
`;
const ImgWrap = styled.div`
  margin-bottom: 17px;
  width: 857px;
  height: 97px;
  img {
    width: 100%;
  }
  @media ${theme.mq.desktop}{
    margin-bottom: 0.8854vw;
    width: 44.6354vw;
    height: 5.0521vw;
  }
  @media ${theme.mq.mobile}{
    margin-bottom: 5.5556vw;
    width: 88.8889vw;
    height: 10vw;
  }
`;
const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  button {
    height: 72px;
    border: 2px solid #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
    }
  }
  button:first-child {
    width: 242px;
  }
  button:last-child {
    position: relative;
    width: 216px;
    pointer-events: none;
  }
  .outlinedText {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.80);
    border-radius: 10px;
    font-size: 2.0rem;
    color: ${theme.colors.accent};
    text-shadow: 0px 0px 10px rgba(254, 235, 141, 0.60);
    &::before {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      white-space: nowrap;
      --stroke-color: #031012;
    }
  }
  @media ${theme.mq.desktop}{
    gap: 1.25vw;
    button {
      height: 3.75vw;
      border-radius: 0.5208vw;
    }
    button:first-child {
      width: 12.6042vw;
    }
    button:last-child {
      width: 11.25vw;
    }
    .outlinedText {
      border-radius: 0.5208vw;
      font-size: 1.0417vw;
    }
  }
  @media ${theme.mq.mobile}{
    gap: 4.4444vw;
    margin-bottom: 6.6667vw;
    button {
      height: 13.3333vw;
      border-radius: 2.2222vw;
      border-width: 0.2778vw;
    }
    button:first-child {
      width: 42.2222vw;
    }
    button:last-child {
      position: relative;
      width: 42.2222vw;
      background: #000;
      img {
        width: unset;
        height: 100%;
      }
    }
    .outlinedText {
      border-radius: 2.7778vw;
      font-size: 3.8889vw;
    }
  }
`;

//Modal
const ModalText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.4;
  font-size: 4.0rem;
  .outlinedText {
    text-shadow: 0px 0px 12px rgba(254, 235, 141, 0.80);
    &:before {
      --stroke-width: 4px;
    }
  }
  @media ${theme.mq.desktop}{
    font-size: 2.0833vw;
  }
  @media ${theme.mq.mobile}{
    font-size: 6.1111vw;
    .outlinedText {
      &:before {
        --stroke-width: 0.8333vw;
      }
    }
  }
`;
const Title = styled.p` 
  color: ${theme.colors.accent};
`;
const SubTitle = styled.p`
  margin-bottom: 20px;
  color: #fff;
  @media ${theme.mq.desktop}{
    margin-bottom: 1.0417vw;
  }
  @media ${theme.mq.mobile}{
    margin-bottom: 4.4444vw;
  }
`;
const ModalImgWrap = styled.div`
  margin-bottom: 40px;
  width: 218px;
  height: 218px;
  > img {
    width: 100%;
    transform: scale(1.2);
  }
  @media ${theme.mq.desktop}{
    margin-bottom: 2.0833vw;
    width: 11.3542vw;
    height: 11.3542vw;
  }
  @media ${theme.mq.mobile}{
    margin-bottom: 6.6667vw;
    width: 35.5556vw;
    height: 35.5556vw;
  }
`

const ScrollBtn = styled.div`
  width: 8.8889vw;
  height: 8.8889vw;
  transform: rotate(-90deg);
  animation: move-wheel 1.5s linear infinite;
  img {
    width: 100%;
  }
  @keyframes move-wheel {
    0% {
      opacity: 0.75;
      transform: rotate(-90deg) translateX(-1rem);
    }
    50% {
      opacity: 1;
      transform: rotate(-90deg) translateX(1rem);
    }
    100% {
      opacity: 0.75;
      transform: rotate(-90deg) translateX(-1rem);
    }
  }
`;
