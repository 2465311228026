import React, { ReactNode } from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";

interface BtnProps {
  children?: ReactNode;
  imgSrc?: string;
  linkUrl?: string;
  width?: number;
  height?: number;
  fontSize?: number;
}
const Btn = ({
  children,
  imgSrc,
  linkUrl,
  width,
  height,
  fontSize,
}: BtnProps) => {
  return (
    <StyledSelf
      href={linkUrl ? linkUrl : "/#"}
      target={"_blank"}
      width={width}
      height={height}
    >
      <BtnContent fontSize={fontSize}>
        {imgSrc && (
          <ImgWrap>
            <img src={imgSrc} alt={"button 이미지"} />
          </ImgWrap>
        )}
        <p className={"btnText"}>{children}</p>
      </BtnContent>
    </StyledSelf>
  );
};

export default Btn;

const StyledSelf = styled.a<BtnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: 1px solid #00282c;
  background: #006b75;
  color: #fff;
  transition: background 0.3s ease;
  &:hover {
    background: #00282c;
  }

  @media ${theme.mq.desktop} {
    height: 2.9167vw;
    min-width: 90px;
    min-height: 27px;
    border-radius: 0.2083vw;
  }
  @media ${theme.mq.mobile} {
    /* width: 66.6667vw; */
    height: 12.2222vw;
    border-radius: 1.1111vw;
  }
`;
const BtnContent = styled.div<BtnProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .btnText {
    margin-top: 2px;
    font-size: ${({ fontSize }) => (fontSize ? fontSize + "rem" : "1.6rem")};
    margin-bottom: 0;
    color: #fff;
  }
  @media ${theme.mq.desktop} {
    gap: 0.4167vw;
    .btnText {
      margin-top: 0.1042vw;
      font-size: ${({ fontSize }) =>
        fontSize ? (fontSize * 1000) / 1920 + "vw" : "0.9375vw"};
    }
  }
  @media ${theme.mq.mobile} {
    gap: 1.6667vw;
    .btnText {
      margin-top: 0.5556vw;
      font-size: ${({ fontSize }) =>
        fontSize ? (fontSize * 1000) / 360 + "vw" : "3.8889vw"};
    }
  }
`;
const ImgWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  img {
    width: 100%;
  }
  @media ${theme.mq.desktop} {
    width: 1.0417vw;
  }
  @media ${theme.mq.mobile} {
    width: 4.4444vw;
  }
`;
