import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from '../../styles/theme';
import { logoCorporation, logoFooter } from '../../assets/images';

const Footer = () => {
  return (
    <FooterWrap>
      <BgWrap>
        <ContentWrap>
          <LogoWrap>
            <img src={logoFooter} alt="CARRIE VERSE 로고" />
          </LogoWrap>
          <TextWrap>
            <BtnWrap>
              <div>
                <Link to="/ProbabilityInformation" target="_blank">
                  Probability Information
                </Link>
                <span />
              </div>

              <p>
                <a href="/Terms" target="_blank">
                  Terms of Use
                </a>
                <span />
                <a href="/Privacy" target="_blank">
                  Privacy Policy
                </a>
              </p>
            </BtnWrap>
            <p>
              <span>
                <img src={logoCorporation} alt="" />
              </span>
              CARRIEVERSE CORP. ALL RIGHTS RESERVED.
            </p>
          </TextWrap>
        </ContentWrap>
      </BgWrap>
    </FooterWrap>
  );
};

export default Footer;

const FooterWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  background: #fff;
  @media ${theme.mq.mobile} {
    height: 177px;
  }
`;
const BgWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 107, 117, 0.15);
`;
const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 1400px;
  height: 100%;
  @media ${theme.mq.desktop} {
    width: 72.9167vw;
  }
  @media ${theme.mq.mobile} {
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: auto;
  }
`;
const LogoWrap = styled.div`
  width: 110px;
  img {
    width: 100%;
  }
  @media ${theme.mq.mobile} {
    width: 80px;
    height: 40px;
    margin-bottom: 20px;
  }
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${theme.colors.btn};
  font-size: ${theme.fontSizes.footer};
  line-height: 2.28;
  p {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 17px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -3px;
    }
  }
  @media ${theme.mq.mobile} {
    align-items: center;
    font-size: 12px;
    line-height: 2.6;
    p {
      gap: 1.1111vw;
    }
    gap: 10px;
  }
`;
const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 18px;

  p {
    display: flex;
    gap: 18px;
  }
  a {
    color: ${theme.colors.btn};
  }
  span {
    width: 1px;
    height: 14px;
    background: ${theme.colors.btn};
  }
  div {
    display: flex;
    align-items: center;
    gap: 18px;
    span {
      display: block;
      @media ${theme.mq.mobile} {
        display: none;
      }
    }
  }

  @media ${theme.mq.mobile} {
    display: flex;
    flex-direction: column;
    gap: 6px;
    line-height: 32px;
    span:first-child {
      display: none;
    }

    a {
      display: flex;
      align-items: center;
      height: 22px;
    }

    /* 모바일 화면에서 <Link>와 <span> 사이의 <span> 요소만 보이게 함 */
    p > span {
      display: block;
    }
  }
`;
