import React from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";

interface IconProps {
    clicked: string;
}
interface HamburgerIconProps {
    navState: boolean;
    setNavState: React.Dispatch<React.SetStateAction<boolean>>;
}

const HamburgerIcon: React.FC<HamburgerIconProps> = ({navState,setNavState}) => {
    return (
        <MenuLabel htmlFor="navi-toggle" onClick={()=>setNavState(!navState)}>
            <Icon clicked={navState.toString()} />
        </MenuLabel>
    );
};

export default HamburgerIcon;
const MenuLabel = styled.label`
  position: fixed;
  top: 12px;
  right: 20px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  z-index: 1000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${theme.mq.mobile}{
    top: 3.3333vw;
    right: 5.55vw;
    height: 8.8889vw;
    width: 8.8889vw;
  }
`;

const Icon = styled.span<IconProps>`
  position: relative;
  background-color: ${({clicked}) => (clicked==="true" ? "transparent" : "#FAF7E7")};
  width: 22px;
  height: 1px;
  display: inline-block;
  transition: all 0.3s;
  @media ${theme.mq.mobile}{
    width: 6.1111vw;
    height: 0.5556vw;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: #FAF7E7;
    width: ${({clicked}) => (clicked==="true" ? "26px" : "22px")};
    height: 1px;
    display: inline-block;
    left: ${({clicked}) => (clicked==="true" ? "-2px" : "0")};
    transition: all 0.3s;
    @media ${theme.mq.mobile}{
      width: ${({clicked}) => (clicked==="true" ? "7.2222vw" : "6.1111vw")};
      height: 0.5556vw;
      left: ${({clicked}) => (clicked==="true" ? "-0.5556vw" : "0")};
    }
  }
  &::before {
    top: ${({clicked}) => (clicked==="true"  ? "0" : "-8px")};
    transform: ${({clicked}) => (clicked==="true" ? "rotate(135deg)" : "rotate(0)")};
    @media ${theme.mq.mobile}{
      top: ${({clicked}) => (clicked==="true"  ? "0" : "-2.2222vw")};
    }
  }
  &::after {
    top: ${({clicked}) => (clicked==="true" ? "0" : "8px")};
    transform: ${({clicked}) => (clicked==="true"  ? "rotate(-135deg)" : "rotate(0)")};
    @media ${theme.mq.mobile}{
      top: ${({clicked}) => (clicked==="true" ? "0" : "2.2222vw")};
    }
  }
`;
