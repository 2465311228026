import Home from './pages/Home';
import Infomation from './pages/Infomation';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="error"
          element={
            <div style={{ fontSize: '16px' }}>404 없는 페이지입니다.</div>
          }
        />
        <Route path={'/Terms'} element={<Terms />} />
        <Route path={'/Privacy'} element={<Privacy />} />
        <Route path="/ProbabilityInformation/*" element={<Infomation />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
