import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CharacterCard from '../components/Infomation/CharacterCard';
import CharacterSumCard from '../components/Infomation/CharacterSumCard';
import CardSum from '../components/Infomation/CardSum';
import CardEnchant from '../components/Infomation/CardEnchant';
import CharacterLevel from '../components/Infomation/CharacterLevel';
import PartsEnchant from '../components/Infomation/PartsEnchant';
import Passive from '../components/Infomation/Passive';

const Infomation = () => {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.pathname.split('/').pop() as string;
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView();
    }
  }, [location]);

  return (
    <Body>
      <Section id="CharacterSummoner">
        <CharacterCard />
      </Section>
      <Section id="CharacterSummoning">
        <CharacterSumCard />
      </Section>
      <Section id="CardSummoning">
        <CardSum />
      </Section>
      <Section id="CardEnchant">
        <CardEnchant />
      </Section>
      <Section id="CharacterLevelUp">
        <CharacterLevel />
      </Section>
      <Section id="PartsEnchant">
        <PartsEnchant />
      </Section>
      <Section id="PartsPassiveSkill">
        <Passive />
      </Section>
    </Body>
  );
};

export default Infomation;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: black;
  font-family: sans-serif;
`;

const Section = styled.section`
  margin: 20px 0;
  width: 80%;
`;
